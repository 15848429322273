import React from 'react';

import { SvcSelectBox } from '@/svc/components';

export default function CorsiRecordFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  const onValueChanged = ({value}) => {
    setValue(value);
  }

  return (
    <SvcSelectBox
      domain='Xtra'
      entity='corsi'
      value={value}
      setValue={onValueChanged}
    />
  );
}

CorsiRecordFilter.key = 'corsiRecord';
CorsiRecordFilter.label = 'Corso';
CorsiRecordFilter.colSpan = 2;