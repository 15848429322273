import React, { useMemo } from 'react';

import { Column, RequiredRule, Lookup } from 'devextreme-react/data-grid';

import { EntityListPage, useAuth, useSvcLookupDataGridColumnStore } from '@/svc';

export default function ServiziPrenotazioneAziendePage() {
  const { user } = useAuth();
  const aziendeDatasource = useSvcLookupDataGridColumnStore({
    domain: 'Auth',
    entity: 'aziende'
  });

  const servizioDatasource = useSvcLookupDataGridColumnStore({
    domain: 'Book',
    entity: 'serviziPrenotazione'
  });

  const isUserAdmin = useMemo(() => user.IsAdmin === true, [user]);

  return <EntityListPage
    domain='Book'
    entity='serviziPrenotazioneAziende'
    editRoute='/book/ServiziPrenotazioneAziende/edit'
  >
    <EntityListPage.Columns>
      <Column
        caption='Azienda'
        dataField='azienda.id'
        dataType='string'
        >
          <Lookup
            dataSource={aziendeDatasource}
            displayExpr="ragionesociale"
            valueExpr="id"
          />
          <RequiredRule />
      </Column>
      <Column
        caption='Servizio'
        dataField='servizio.id'
        dataType='string'>
          <Lookup
            dataSource={servizioDatasource}
            displayExpr="nome"
            valueExpr="id"
          />
          <RequiredRule />
      </Column>
      <Column
        caption='Max Persone'
        dataField='max_persone'
        dataType='number'>
          <RequiredRule />
      </Column>
      <Column
        caption='Tipo Azienda'
        dataField='tipoAzienda'>
          <Lookup
            dataSource={[{id: 100, nome: 'Scuola di Sci'}, {id: 101, nome: 'Noleggio'}]}
            displayExpr="nome"
            valueExpr="id"
          />
      </Column>
      <Column
        caption='Tipo Listino'
        dataField='tipoListino'>
          <Lookup
            dataSource={[{id: 10, nome: 'Con Riduzioni'}, {id: 20, nome: 'Prezzi Assoluti'}, {id: 30, nome: 'Prezzo Unitario'}]}
            displayExpr="nome"
            valueExpr="id"
          />
      </Column>
      <Column
        visible={isUserAdmin}
        caption='Usa Tabella Disponibilità'
        dataType='boolean'
        dataField='usa_tabella_disponibilita'>
          <RequiredRule />
      </Column>
      <Column
        visible={isUserAdmin}
        caption='ID Prodotto Wordpress'
        dataField='wp_product_id'>
          <RequiredRule />
      </Column>
    </EntityListPage.Columns>
  </EntityListPage>
}