import React, { useCallback, useRef, useState } from 'react';

import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';

function selectItems(treeView, value) {
  if (Array.isArray(value)) {
    value.forEach(v => treeView.selectItem(v));
  } else {
    treeView.selectItem(value);
  }
}

export default function TreeSelectBox(props) {
  const {
    valueExpr = 'id', displayExpr = 'displayText', parentIdExpr = 'padre.id',
    dataSource, placeholder = 'Seleziona un valore...', multiple = false,
    value: initialValue,
    onValueChanged
  } = props;

  const treeView = useRef();
  const [ value, setValue ] = useState(initialValue);

  const onContentReady = useCallback(e => {
    selectItems(e.component, initialValue)
  }, [ initialValue ]);

  const itemSelectionChanged = useCallback(e => {
    const selectedItems = e.component.getSelectedNodes()
      .map(node => node.itemData);

    if (typeof onValueChanged === 'function') {
      if (multiple) {
        onValueChanged(selectedItems);
      }
      else {
        if (selectedItems && selectedItems.length > 0) {
          onValueChanged(selectedItems[0]);
        }
        else setValue(null); 
      }
    }

    setValue(selectedItems);    
    
  }, [ setValue, onValueChanged ]);

  const treeViewRender = useCallback(() => {
    return (
      <TreeView
        dataSource={dataSource}
        ref={treeView}
        dataStructure="plain"
        keyExpr={valueExpr}
        parentIdExpr={parentIdExpr}
        selectionMode={multiple ? 'multiple' : 'single'}
        displayExpr={displayExpr}
        selectByClick={true}
        onContentReady={onContentReady}
        onItemSelectionChanged={itemSelectionChanged}
      />
    );
  }, [
    dataSource, multiple, treeView, valueExpr, parentIdExpr,
    displayExpr, onContentReady, itemSelectionChanged
  ]);

  const syncTreeViewSelection = useCallback(e => {
    setValue(e.value);

    if (typeof onValueChanged === 'function') {
      onValueChanged(e.value);
    }

    if (!treeView.current) return;

    if (!e.value) {
      treeView.current.instance.unselectAll();
    } else {
      selectItems(treeView.current?.instance, e.value);
    }
  }, [ setValue, onValueChanged, treeView ]);
  
  return (
    <DropDownBox
      value={value}
      valueExpr={valueExpr}
      displayExpr={displayExpr}
      placeholder={placeholder}
      showClearButton={true}
      dataSource={dataSource}
      onValueChanged={syncTreeViewSelection}
      contentRender={treeViewRender}
    />
  );
}

