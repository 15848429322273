import React from 'react';

import { Column, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function ServiziPrenotazionePage() {
  return <EntityListPage
    domain='Book'
    entity='serviziPrenotazione'
    editRoute='/book/ServiziPrenotazione/edit'
  >
    <EntityListPage.Columns>
      <Column
        caption='Data Creazione'
        dataField='data_creazione'
        dataType='datetime'>
      </Column>
      <Column
        caption='Nome'
        dataField='nome'
        dataType='string'
        >
          <RequiredRule />
      </Column>
    </EntityListPage.Columns>
  </EntityListPage>
}