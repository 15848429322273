import React, { useCallback, useMemo, useState } from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';
import shortid from 'shortid';

import { EntityEditPage, useSvcDatasource, useSvcAziendaType } from '@/svc';

export default function ListinoPeriodiEditPage() {
  const [ displayTipoCalcolo20Fields, setDisplayTipoCalcolo20Fields ] = useState(false);

  const { isHotel, isServizio } = useSvcAziendaType();

  const tipoCameraAziendaDataSource = useSvcDatasource({
    domain: 'Book',
    entity: 'tipoCameraAzienda',
    lookupKey: 'id'
  });
  const trattamentoPrenotazioneDataSource = useSvcDatasource({
    domain: 'Book',
    entity: 'trattamentoPrenotazione',
    lookupKey: 'codice'
  });
  const serviziPrenotazioneAziendeDataSource = useSvcDatasource({
    domain: 'Book',
    entity: 'serviziPrenotazioneAziende',
    lookupKey: 'id'
  });

  const checkDisplayTipoCalcolo20Fields = useCallback((tipoListino) => {
    if (tipoListino === 20) {
      if (!displayTipoCalcolo20Fields) {
        setDisplayTipoCalcolo20Fields(!!tipoListino);
      }
    }
    else {
      setDisplayTipoCalcolo20Fields(false);
    }
  }, [ displayTipoCalcolo20Fields ]);

  const tipoCalcolo20fields = useMemo(() => [
    <SimpleItem
      key={shortid.generate()}
      visible={!isServizio}
      dataField='tariffa_3'
      editorType='dxNumberBox'>
      <Label text='Tariffa 3 persone' />
    </SimpleItem>,
    <SimpleItem
      key={shortid.generate()}
      visible={!isServizio}
      dataField='tariffa_4'
      editorType='dxNumberBox'>
      <Label text='Tariffa 4 persone' />
    </SimpleItem>
  ], [ isServizio ]);

  const tipoCameraAziendaSelectBox = useMemo(() => ({
    dataSource: tipoCameraAziendaDataSource,
    displayExpr: 'tipoCamera.displayText',
    onValueChanged(e) {
      checkDisplayTipoCalcolo20Fields(e.value?.tipoListino);
      setTariffaDUSDisabled(e.value?.min_persone !== 1);
    }
  }), [ tipoCameraAziendaDataSource, checkDisplayTipoCalcolo20Fields ]);

  const [ tariffaDUSDisabled, setTariffaDUSDisabled ] = useState(true);

  return <EntityEditPage
    domain='Book'
    entity='listinoPeriodi'>
      <EntityEditPage.EditForm>
        {(datarow) => {
          checkDisplayTipoCalcolo20Fields(datarow.tipoCameraAzienda?.tipoListino);
          setTariffaDUSDisabled(datarow.tipoCameraAzienda?.min_persone !== 1);

          return (
            <React.Fragment>
              <SimpleItem dataField='data_inizio' editorType='dxDateBox'>
                <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField='data_fine' editorType='dxDateBox'>
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                visible={!isServizio}
                dataField='tipoCameraAzienda'
                editorType='dxSelectBox'
                editorOptions={tipoCameraAziendaSelectBox}>
                  <Label text='Tipo Camera' />
                  <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField='tariffa_base' editorType='dxNumberBox'>
                <Label text='Tariffa Base' />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                visible={!isServizio}
                dataField='tariffa_1'
                editorType='dxNumberBox'
                editorOptions={{
                  disabled:tariffaDUSDisabled
                }}>
                <Label text={'Tariffa 1 persona'
                  + ' (Se impostato andrà a sovrascrivere "Doppio Uso Singolo"'
                  + ' nelle impostazioni del Listino)'} />
              </SimpleItem>
              { displayTipoCalcolo20Fields && tipoCalcolo20fields }
              <SimpleItem
                visible={isHotel}
                dataField='trattamento'
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: trattamentoPrenotazioneDataSource,
                  displayExpr: 'descrizione'
                }}>
                  <Label text='Trattamento' />
                  <RequiredRule />
              </SimpleItem>
              <SimpleItem
                visible={isServizio}
                dataField='servizioAzienda'
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: serviziPrenotazioneAziendeDataSource,
                  displayExpr: 'servizio.displayText'
                }}>
                  <Label text='Servizio' />
              </SimpleItem>
            </React.Fragment>
          );
        }}
      </EntityEditPage.EditForm>
  </EntityEditPage>
}