import React, { useCallback, useMemo, useState } from 'react';

import { LoadPanel } from 'devextreme-react';
import { Column, Button, RequiredRule } from 'devextreme-react/data-grid';

import {
  useAuth,
  EntityListPage,
  useSvcCustomRequest,
  useSvcAziendaType
} from '@/svc';
import { useModalConfirm, SkivalleePreviewModal } from '@/ui/components';
import { Toast } from '@/ui/utils';

export default function PrenotazioniPage() {
  const { user } = useAuth();
  const [ listWidgetInstance, setListWidgetInstance ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ displaySkivalleeModal, setDisplaySkivalleeModal ] = useState(false);
  const [ currentSkivalleeOrderId, setCurrentSkivalleeOrderId ] = useState(void 0);

  const { isServizio } = useSvcAziendaType();

  const [ confirmPrenotazioneModal, userConfirmsPrenotazione ] = useModalConfirm({
    title: 'Attenzione',
    question: 'Confermare la prenotazione selezionata?'
  });

  const [ confirmSwitchAssicurazioneModal, userConfirmsSwitchModal ] = useModalConfirm({
    title: 'Attenzione',
    question: 'Sei sicuro di voler cambiare lo stato dell\'assicurazione?'
  });

  const onListWidgetInitialized = ({ component }) => {
    setListWidgetInstance(component.instance());
  };

  const { customRequest } = useSvcCustomRequest('Book', 'prenotazioni');
  const confermaPrenotazione = useCallback(async ({ row }) => {
    if (await userConfirmsPrenotazione()) {
      setLoading(true);

      try {
        await customRequest({
          command: 'Conferma',
          key: row.data.id
        });
        Toast('success', 'Prenotazione confermata con successo!');
        await listWidgetInstance.getDataSource().reload();
        listWidgetInstance.refresh();
        listWidgetInstance.repaint();  
      }
      catch (e) {
        Toast('error', e.message, 8000);
      } finally {
        setLoading(false);
      }
    }
  }, [ customRequest, listWidgetInstance, userConfirmsPrenotazione ]);

  const switchStatoAssicurazione = useCallback(async ({ row }) => {
    if (await userConfirmsSwitchModal()) {
      setLoading(true);

      try {
        await customRequest({
          command: 'SwitchStatoAssicurazione',
          key: row.data.id
        });
        Toast('success', 'Stato assicurazione modificato con successo!!');
        await listWidgetInstance.getDataSource().reload();
        listWidgetInstance.refresh();
        listWidgetInstance.repaint();  
      }
      catch (e) {
        Toast('error', e.message, 8000);
      } finally {
        setLoading(false);
      }
    }
  }, [ customRequest, listWidgetInstance, userConfirmsSwitchModal ]);
  
  const triggerDisplaySkivalleeModal = useCallback(async ({ row }) => {
    setCurrentSkivalleeOrderId(row.data.wp_order_id);
    setDisplaySkivalleeModal(true);
  }, []);

  // Stabilisce se mostrare la colonna azione per confermare la reservation.
  const displayAdditionalButtons = useMemo(
    () => user.livelloRecord?.id === 1,
    [ user ]
  );
  // Stabilisce se il pulsante per confermare la reservation dev'essere visibile.
  const displayConfirmReservationButton = useCallback(({ row }) => (
    !row.data.flag_eliminato && row.data.abilitaConferma
  ), []);

  const displaySwitchInsuranceStatusButton = useCallback(({ row }) => (
    row.data.statoAssicurazione?.canSwitch === true
  ), []);

  const displaySkivalleePreviewButton = useCallback(({ row }) => (
    row.data.skivallee_amount > 0 && 
    Boolean(row.data.wp_order_id)
  ), []);

  return (
    <>
      <EntityListPage
        domain='Book'
        entity='prenotazioni'
        editRoute='/book/Prenotazioni/edit'
        onInit={onListWidgetInitialized}
      >
        {displayAdditionalButtons &&
          <EntityListPage.RowButtons>
            <Button
              icon='fa fa-check'
              hint='Conferma questa prenotazione'
              visible={displayConfirmReservationButton}
              onClick={confermaPrenotazione} />
            <Button
              icon='fa fa-refresh'
              hint='Cambia stato assicurazione'
              visible={displaySwitchInsuranceStatusButton}
              onClick={switchStatoAssicurazione} />
            <Button
              icon='fa fa-info'
              hint='Visualizza dettaglio skivallee'
              visible={displaySkivalleePreviewButton}
              onClick={triggerDisplaySkivalleeModal} />
          </EntityListPage.RowButtons>
        }
        <EntityListPage.Columns>
          <Column
            caption='Stato Prenotazione'
            dataField='statoPrenotazione.descrizione'
            dataType='string'>
          </Column>
          <Column
            caption='Prima Notte'
            dataField='prima_notte'
            dataType='date'
            >
              <RequiredRule />
          </Column>

          <Column
            caption='Ultima Notte'
            dataField='ultima_notte'
            dataType='date'>
              <RequiredRule />
          </Column>

          <Column
            caption={isServizio ? 'Servizio' : 'Tipo Camera'} 
            dataField={isServizio
              ? 'servizioAzienda.servizio.displayText'
              : 'tipoCameraAzienda.tipoCamera.displayText'}>
              <RequiredRule />
          </Column>

          <Column
            caption='Descrizione'
            dataField='riferimento'>
              <RequiredRule />
          </Column>

          <Column
            caption='Stato Assicurazione'
            dataField='statoAssicurazione.descrizione'>
          </Column>

          <Column
            caption='Totale SkiVallee'
            dataField='skivallee_amount'
            dataType='number'>
          </Column>
        </EntityListPage.Columns>
      </EntityListPage>
      <LoadPanel visible={loading} shadingColor='rgba(0, 0, 0, 0.3)' />
      {confirmPrenotazioneModal}
      {confirmSwitchAssicurazioneModal}
      <SkivalleePreviewModal 
        wpOrderId={currentSkivalleeOrderId}
        displayModal={displaySkivalleeModal}
        setDisplayModal={setDisplaySkivalleeModal}
      />
    </>
  );
}