import { useCallback, useContext } from 'react';

import { SvcContext } from '@/svc';

export default function useSvcDelete(domain, entity) {
  const { client, user } = useContext(SvcContext);

  const token = user.token_login;

  const deleteEntity = useCallback(
    (record) => client.deleteEntity(domain, entity, record, { token }),
    [ client, domain, entity, token ]
  );

  return deleteEntity;
}