import React, { useState, useRef } from 'react';

import { Form, ProgressBar } from 'devextreme-react';
import { Label, RequiredRule, SimpleItem } from 'devextreme-react/form';

import { useSvcCustomRequest } from '@/svc';
import { Modal } from '@/ui/components';

const simulateSendSms = process.env.REACT_APP_FAKE_SEND_SMS === 'true';

export default function EntitySendSmsDialog(props) {
  const {
    domain, entity, defaultData = {},
    sendMode = 'single', sendNumbers = [],
    modalVisible, onModalClosed,
    modalTitle
  } = props;
  
  const [datarow, setDatarow] = useState(null);
  const [isNumberBoxVisible, setIsNumberBoxVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayCloseSuccessButton, setDisplayCloseSuccessButton] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressMax, setProgressMax] = useState(0);
  const [progressResults, setProgressResults] = useState([]);
  const formRef = useRef();
  const { customRequest } = useSvcCustomRequest(domain, entity);

  const resolveModalValue = (value) => {
    onModalClosed(value ? [...value] : value);
    setDatarow(null);
    setLoading(false);
    setProgress(0);
    setProgressMax(0);
    setProgressResults([]);
  }

  const onUndoClick = () => {
    resolveModalValue(void 0);
  }

  const sendMultipleSMS = async (smsNumbers) => {
    let sendResult = {
      success: true,
      error: void 0, 
      results: [],
      totalCount: void 0,
      errorCount: void 0
    };
    
    if (simulateSendSms) {
      await (new Promise((resolve) => setTimeout(() => resolve(), 1500)));
      for (let number of smsNumbers) {
        let success = Math.random() < 0.5;
        let message = success ? void 0 : `${number} - Errore generico.`;
        sendResult.results.push({
          success, message
        });
      }
    }
    else {
      try {
        const { smsMessagesResult } = await customRequest({
          command: 'SendSMS',
          smsMessageList: smsNumbers.map(smsNumber => ({
            smsNumber,
            smsText: datarow.smsText
          }))
        });
        const {
          AllOk, TotalCount, ErrorCount, WarnCount, SuccessCount, 
          ErrorMessages, WarnMessages
        } = smsMessagesResult;
        sendResult.results = [
          ...(ErrorMessages ?? []).map(msg => ({
            success: false,
            message: msg
          })),
          ...(WarnMessages ?? []).map(msg => ({
            success: false,
            message: msg,
            isWarn: true
          }))
        ]
        sendResult.totalCount = TotalCount;
        sendResult.errorCount = ErrorCount;
      }
      catch (e) {
        sendResult.success = false;
        sendResult.error = e;
      }
    }
    return sendResult;
  }

  const sendSMS = async (smsMessagePayload) => {
    let successResult = {};
    
    if (simulateSendSms) {
      await (new Promise((resolve) => setTimeout(() => resolve(), 1500)));
      successResult.success = Math.random() < 0.5;
      successResult.message = successResult.success 
        ? 'Si è verificato un errore in fase di invio sms.'
        : null;
    }
    else {
      try {
        await customRequest({
          command: 'SendSMS',
          smsMessage: smsMessagePayload
        });
        successResult.success = true;
      }
      catch (e) {
        successResult.success = false;
        successResult.message = e;
      }
    }
    return successResult;
  }

  const onConfirmClick = async () => {
    const { isValid } = formRef.current.instance.validate();
    if (!isValid) return;
    
    setLoading(true);
    setProgressResults([]);
    // deve tentare l'invio dell'sms
    if (sendMode === 'single') {
      // Invio di un solo SMS.
      setProgress(0);
      setProgressMax(1);
      const result = await sendSMS(datarow);
      setProgress(progress + 1);
      setProgressResults(
        [
          ...progressResults,
          result
        ]
      );
    }
    else if (sendMode === 'multiple-client') {
      // Invio di più SMS in un colpo solo.
      setProgress(0);
      setProgressMax(sendNumbers.length);

      for (let number of sendNumbers) {
        // await call
        const result = await sendSMS({
          ...datarow,
          smsNumber: number
        });
        setProgress(progress + 1);
        setProgressResults(
          [
            ...progressResults,
            result
          ]
        );
      }
    }
    else if (sendMode === 'multiple-server') {
      // invio di uno stack di sms in una richiesta sola
      setProgress(0);
      setProgressMax(sendNumbers.length);
      const { results } = await sendMultipleSMS(sendNumbers);
      setProgress(sendNumbers.length);
      setProgressResults(
        [
          ...progressResults,
          results
        ]
      );
    }
    setDisplayCloseSuccessButton(true);
  }

  let modalBody = void 0;
  if (!modalVisible) {
    return <></>;
  }
  else if (!datarow) {
    setDatarow({
      ...defaultData
    });
    setIsNumberBoxVisible(
      sendMode === 'single'
    );
    setDisplayCloseSuccessButton(false);
  }
  else if (datarow && !loading) {
    modalBody = <React.Fragment>
        <Form
          ref={formRef}
          colCount={1}
          formData={datarow}
          id="form">
          {
            isNumberBoxVisible && <SimpleItem
              dataField='smsNumber'
              editorType='dxTextBox'
            >
              <Label text='Numero di cellulare' />
              <RequiredRule />
            </SimpleItem>
          }
          <SimpleItem
            dataField='smsText'
            editorType='dxTextArea'
          >
            <Label text='Messaggio' />
            <RequiredRule />
          </SimpleItem>
        </Form>
    </React.Fragment>;
  }
  else if (loading) {
    modalBody = <React.Fragment>
      <div style={{textAlign: 'center', width: '100%'}}>
        Invio in corso ({progress < progressMax ? progress + 1 : progress} di {progressMax})
      </div><br />
      <ProgressBar
        width="100%"
        min={0}
        max={progressMax}
        value={progress}
        statusFormat={(value) => `${value * 100}%`}
      />
      <br />
      <hr />
      {progressResults.map((successEntry) => 
        <>
          <span>
            {successEntry.success ? '✓' : '✘'}
            🡢 &nbsp; {successEntry.message ? successEntry.message : 'SMS preso in carico'} 
          </span><br />
        </>
      )}
    </React.Fragment>
  }
  return <React.Fragment>
    <Modal
        visible={modalVisible}
        minWidth={'40vw'}
        displayCloseButton={false}
      >
      <Modal.Title>
        {modalTitle}
      </Modal.Title>
      <React.Fragment>
        {modalBody}
      </React.Fragment>
      {!displayCloseSuccessButton && !loading && <Modal.Button type="danger" onClick={onUndoClick}>Annulla</Modal.Button>}
      {!displayCloseSuccessButton && !loading && <Modal.Button type="success" onClick={onConfirmClick}>Invia SMS</Modal.Button>}
      {displayCloseSuccessButton && <Modal.Button type="info" onClick={() => resolveModalValue(progressResults)}>Chiudi</Modal.Button>}
    </Modal>
  </React.Fragment>;
}
