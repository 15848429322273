import React from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcSelectBoxConfig } from '@/svc';

export default function OrganigrammaEditPage() {
  const organigrammaSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'HR',
    entity: 'organigramma'
  });

  return (
    <EntityEditPage
      domain='HR'
      entity='organigramma'>
        <EntityEditPage.EditForm>
          {(datarow) => (
            <React.Fragment>
              <SimpleItem
                colSpan={2}
                dataField='descrizione'
                editorType="dxTextBox"
              >
                <Label text="Descrizione" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='nome'
                editorType="dxTextBox"
              >
                <Label text="Descrizione Breve" />
              </SimpleItem>
              <SimpleItem
                dataField='padre'
                editorType='dxSelectBox'
                editorOptions={organigrammaSelectBoxConfig}>
                  <Label text='Padre' />
              </SimpleItem>
            </React.Fragment>
          )}
        </EntityEditPage.EditForm>
    </EntityEditPage>
  );
}