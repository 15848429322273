import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';

import { Column, Button } from 'devextreme-react/data-grid';

import { EntityDataGrid } from '@/svc'
import { NavContext } from '@/navigation';

function DataGridList(props, ref) {
  const {
    domain, entity, filters, dataSourceListOpts,
    columns, rowButtons,
    onInit, onEdit, onClone,
    selectionMode = 'none', onSelectionChanged
  } = props;

  const { currentRoute: { readOnly } } = useContext(NavContext);

  const entityDatagridRef = useRef();
  useImperativeHandle(ref, () => ({
    entityDatagridRef: entityDatagridRef,
    clearSelection: () => {
      entityDatagridRef.current.clearSelection();
    }
  }));

  const isCloneVisible = (evt) => evt.row?.data?.hasCloneCommand;

  return (
    <EntityDataGrid
      ref={entityDatagridRef}
      domain={domain}
      entity={entity}
      filters={filters}
      selectionMode={selectionMode}
      onSelectionChanged={onSelectionChanged}
      onInitialized={(e) => onInit && onInit(e)}
      dataSourceListOpts={dataSourceListOpts}
    >
      {columns}
      <Column type='buttons'>
        {rowButtons}
        <Button visible={isCloneVisible} hint='duplica' icon='repeat' onClick={onClone} />
        <Button icon={readOnly ? 'info' : 'edit'} hint='modifica' onClick={onEdit} />
      </Column>
    </EntityDataGrid>
  );
}

export default forwardRef(DataGridList);