import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { Button } from 'devextreme-react/button';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import { useSvcCustomRequest } from '@/svc';
import { Toast } from '@/ui/utils';

export default function SkivalleePreviewModal(props) {
  const { wpOrderId, displayModal, setDisplayModal } = props;

  const [loading, setLoading] = useState(false);
  const [orderLines, setOrderLines] = useState(void 0);

  const { customRequest: invokeLoadOrderDetail } = useSvcCustomRequest(
    'Book', 'prenotazioni'
  );

  const loadOrderDetail = useCallback(async () => {
    setLoading(true);

    try {
      const { wcOrderLines } = await invokeLoadOrderDetail({
        command: 'SkivalleeDetails',
        key: wpOrderId
      });
      setOrderLines(wcOrderLines);
    } catch (e) {
      Toast('error', 'Non è stato possibile verificare lo stato dell\'ordine, riprova più tardi.');
      setOrderLines(void 0);
      setDisplayModal(false);
    }
    setLoading(false);
    
  }, [invokeLoadOrderDetail, wpOrderId, setDisplayModal]);

  useEffect(() => {
    if (displayModal && wpOrderId && !loading && (orderLines === undefined)) {
      loadOrderDetail();
    }
  }, [displayModal, wpOrderId, loadOrderDetail, loading, orderLines]);

  const getRowVerboseDescription = (row) => {
    const itemVerboseDescription = row.meta_data.find(v => v.key === 'item_itemVerboseDescription').value;
    
    return <span dangerouslySetInnerHTML={{ __html: itemVerboseDescription }}></span>
  };

  const getRowPriceStringValue = (row) => {
    const priceValue = Number(row.total).toFixed(2).replace('.', ',');

    return priceValue;
  }

  const getRowInsuranceStringValue = (row) => {
    const insuranceValue = row.meta_data.find(v => v.key === 'item_insurancePrice').value;

    return Number(insuranceValue).toFixed(2).replace('.', ',');
  };

  const getRowKeyCardStringValue = (row) => {
    const keyCardValue = row.meta_data.find(v => v.key === 'item_keyCardPrice')?.value ?? 0;

    return Number(keyCardValue).toFixed(2).replace('.', ',');
  }

  const triggerClose = () => {
    setDisplayModal(false);
    setLoading(false);
    setOrderLines(void 0);
  };

  return (
    <Popup
      visible={displayModal}
      onHiding={() => triggerClose()}
      dragEnabled={false}
      closeOnOutsideClick={true}
      showCloseButton={true}
      showTitle={true}
      title="Dettaglio Skivallee"
      container=".dx-viewport"
      width={400}
      height={350}
    >
      <Position
        at="center"
        my="center"
        of={'body'}
      />
      {loading && (
        <LoadIndicator />
      )}
      {!loading && orderLines && (
        <ul>
          {orderLines.map(row => (
            <li>
              {row.name} - {getRowVerboseDescription(row)} <br />
              Totale (inclusa ass. e KeyCard): € {getRowPriceStringValue(row)}<br />
              Importo assicurazione: € {getRowInsuranceStringValue(row)}<br />
              Importo KeyCard: € {getRowKeyCardStringValue(row)}
            </li>
          ))}
        </ul>
      )}
    </Popup>
  );
}