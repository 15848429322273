import { useCallback, useContext, useEffect, useState } from 'react';

import { SvcContext } from '@/svc';

export default function useSvcGet(domain, entity, key, { clone = false, manual = false, customNewCommandValue } = {}) {
  const { client, user } = useContext(SvcContext);

  const [ loading, setLoading ] = useState(!manual);
  const [ error, setError ] = useState(null);
  const [ data, setData ] = useState(null);

  
  // Hotfix: al cambiamento della key, vanno resettati i valori di default.
  useEffect(() => {
    setLoading(!manual);
    setError(null);
    setData(null);
  }, [key, manual])


  const token = user.token_login;

  const fetchData = useCallback(() => {
    setLoading(true);
    setError(null);
    setData(null);

    let svcCall = void 0;
    if (clone) {
      svcCall = client.cloneEntity(domain, entity, key, { token });
    } else if (key) {
      svcCall = client.getEntity(domain, entity, key, { token });
    } else {
      svcCall = client.newEntity(domain, entity, { token, customNewCommandValue });
    }
    return svcCall
      .then(setData, setError)
      .finally(() => setLoading(false));
  }, [ client, clone, domain, entity, key, token, setLoading, setError, setData, customNewCommandValue ]);

  useEffect(() => {
    if (!manual) fetchData();
  }, [ manual, fetchData ]);
  return [ { loading, error, data }, fetchData ];
}