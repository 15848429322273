import React, { useMemo } from 'react';

import { 
  ClientiCategorieListFilter,
  ClientiRecordFilter, 
  CodiceFiscaleFilter,
  ContattiFlagsList,
  CorsiRecordFilter,
  CorsiTipologiaRecordFilter,
  DataInizioFilter,
  GenericTextFilter, 
  GiorniFilter, 
  MaxEntriesFilter,
  PIVAFilter
} from './items';

const itemToComponent = {
  [ClientiCategorieListFilter.key]: ClientiCategorieListFilter,
  [ClientiRecordFilter.key]: ClientiRecordFilter,
  [CodiceFiscaleFilter.key]: CodiceFiscaleFilter,
  [ContattiFlagsList.key]: ContattiFlagsList,
  [CorsiRecordFilter.key]: CorsiRecordFilter,
  [CorsiTipologiaRecordFilter.key]: CorsiTipologiaRecordFilter,
  [DataInizioFilter.key]: DataInizioFilter,
  [GenericTextFilter.key]: GenericTextFilter,
  [GiorniFilter.key]: GiorniFilter,
  [MaxEntriesFilter.key]: MaxEntriesFilter,
  [PIVAFilter.key]: PIVAFilter,
};

export default function FiltersRenderer(props) {
  const {
    component: Component,
    items = {},
    ...rest
  } = props;

  const compItems = useMemo(() => {
    return Object.entries(items)
      .map(
        ([itemKey, { label }]) => {
          return {
            Component: itemToComponent[itemKey],
            label
          }
        }
      ).filter(({Component}) => Component != null);
  }, [ items ]);

  return <Component items={compItems} {...rest} />;
}

