import React, { useMemo, useImperativeHandle, useRef, forwardRef } from 'react';

import DataGrid, {
  Editing, FilterRow, GroupPanel,
  Grouping, HeaderFilter, Paging, Export,
  SearchPanel, StateStoring, Selection
} from 'devextreme-react/data-grid';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';

import { useSvcDatasource } from '@/svc/hooks';

function onRowPrepared(e) {
  if (e.rowType === 'data' && e.data && e.data.flag_eliminato) {
    e.rowElement.style.textDecoration = 'line-through';
  }
}

function EntityDataGrid(props, ref) {
  const {
    domain, entity, filters, dataSource, dataSourceListOpts,
    allowUpdating = true, onDatasourceLoaded, onContentReady, onInitialized,
    selectionMode = 'none', onSelectionChanged,
    mode = 'popup', pageSize = 50, exportOpts = {
      exportSheetTitle: void 0,
      exportFileName: void 0
    },
    children
  } = props;

  const dxDatagridRef = useRef();
  useImperativeHandle(ref, () => ({
    dxDatagridRef: dxDatagridRef,
    clearSelection: () => {
      dxDatagridRef.current.instance.clearSelection();
    }
  }));

  const svcDataSource = useSvcDatasource({
    domain, entity, filters, listOpts: dataSourceListOpts,
    onDatasourceLoaded, paginate: true
  });

  const gridDatasource = useMemo(() => {
    if (dataSource) {
      return dataSource;
    }

    return svcDataSource;
  }, [ dataSource, svcDataSource ]);

  const onExporting = (e) => {
    const {
      exportSheetTitle, exportFileName
    } = exportOpts;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      exportSheetTitle ?? `Export Dati`
    );

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 
        exportFileName ?? `Export_${new Date().toLocaleString()}.xlsx`);
      });
    });
    e.cancel = true;
  }

  return (
    <DataGrid
      ref={dxDatagridRef}
      showBorders
      columnHidingEnabled
      dataSource={gridDatasource}
      onContentReady={(e) => onContentReady && onContentReady(e)}
      columnAutoWidth={true}
      wordWrapEnabled={true}
      onInitialized={(e) => onInitialized && onInitialized(e)}
      onRowPrepared={onRowPrepared}
      onSelectionChanged={onSelectionChanged}
      onExporting={onExporting}
    >
      <Selection
        mode={selectionMode}
        selectAllMode='allPages'
        showCheckBoxesMode='onClick'
      />
      <Paging defaultPageSize={pageSize} />
      
      <StateStoring enabled={true} type="sessionStorage" storageKey={entity + '_list_store'} />
      <GroupPanel visible={true} />
      <SearchPanel visible={true} highlightCaseSensitive={true} />
      <Grouping autoExpandAll={false} />
      <FilterRow visible={true} />
      <HeaderFilter visible={true} />

      <Editing
        allowUpdating={allowUpdating}
        mode={mode} />

      <Export enabled={true} allowExportSelectedData={false} />
      {children}
    </DataGrid>
  );
}

export default forwardRef(EntityDataGrid);