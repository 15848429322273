import React, { useMemo } from 'react';

import { Column, RequiredRule, Lookup } from 'devextreme-react/data-grid';

import { EntityListPage, useAuth, useSvcLookupDataGridColumnStore } from '@/svc';

export default function TipoCameraAziendaPage() {
  const { user } = useAuth();
  const aziendeDatasource = useSvcLookupDataGridColumnStore({
    domain: 'Auth',
    entity: 'aziende'
  });

  const isUserAdmin = useMemo(() => user.IsAdmin === true, [user]);
  
  return <EntityListPage
    domain='Book'
    entity='tipoCameraAzienda'
    editRoute='/book/TipoCameraAzienda/edit'
    newEnabled={isUserAdmin}
  >
    <EntityListPage.Columns>
      <Column
        visible={isUserAdmin}
        caption='Azienda'
        dataField='azienda.id'
        dataType='string'
        >
          <Lookup
            dataSource={aziendeDatasource}
            displayExpr="ragionesociale"
            valueExpr="id"
          />
          <RequiredRule />
      </Column>
      <Column
        caption='Tipo Camera'
        dataField='tipoCamera.displayText'>
          <RequiredRule />
      </Column>
      <Column
        caption='Min Persone'
        dataField='min_persone'
        dataType='number'>
          <RequiredRule />
      </Column>
      <Column
        caption='Max Persone'
        dataField='max_persone'
        dataType='number'>
          <RequiredRule />
      </Column>
      <Column
        caption='Num Camere'
        dataField='num_camere'
        dataType='number'>
          <RequiredRule />
      </Column>
      {/*
      <Column
        caption='Tipo Azienda'
        dataField='tipoAzienda'>
          <Lookup
            dataSource={[{id: 1, nome: 'Hotel'}, {id: 2, nome: 'Bed & Breakfast'}, {id: 3, nome: 'Appartamenti'}]}
            displayExpr="nome"
            valueExpr="id"
          />
      </Column>
      */}
      <Column
        visible={isUserAdmin}
        caption='Tipo Listino'
        dataField='tipoListino'>
          <Lookup
            dataSource={[{id: 10, nome: 'Con Riduzioni'}, {id: 20, nome: 'Prezzi Assoluti'}, {id: 30, nome: 'Prezzo Unitario'}]}
            displayExpr="nome"
            valueExpr="id"
          />
      </Column>
      <Column
        visible={isUserAdmin}
        dataType='boolean'
        caption='Usa Tabella Disponibilità'
        dataField='usa_tabella_disponibilita'>
          <RequiredRule />
      </Column>
      <Column
        visible={isUserAdmin}
        caption='ID Prodotto Wordpress'
        dataField='wp_product_id'>
          <RequiredRule />
      </Column>
    </EntityListPage.Columns>
  </EntityListPage>
}