import React from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage } from '@/svc';


export default function TipoCameraEditPage() {
  return <EntityEditPage
    domain='Book'
    entity='tipoCamera'
  >
    <EntityEditPage.EditForm>
      {(datarow) => (
        <React.Fragment>
          <SimpleItem dataField="nome" editorType="dxTextBox">
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField="codice_gest_esterno" editorType="dxTextBox">
            <Label text="Codice Gestionale Esterno" />
          </SimpleItem>
        </React.Fragment>
      )}
    </EntityEditPage.EditForm>
  </EntityEditPage>
}