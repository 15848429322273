import React, { useCallback, useMemo, useState } from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useAuth, useSvcSelectBoxConfig } from '@/svc';

export default function TipoCameraDisponibilitaEditPage() {
  const { user } = useAuth();
  const [isRangeModel, setIsRangeModel] = useState(false);
  const [isReadonly, setIsReadonly] = useState(false);
  const [customSaveCommandValue] = useMemo(() => {
    return [
      isRangeModel ? 'BulkSave' : void 0
    ];
  }, [isRangeModel])

  const tipoCameraAziendaSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Book',
    entity: 'tipoCameraAzienda',
    paginate: true
  });

  const onDatarowReady = useCallback((datarow) => {
    if (datarow.isRangeModel) {
      setIsRangeModel(true);
    }
    setIsReadonly(datarow.id > 0);
  }, []);

  const fromField = (
    <SimpleItem
      dataField='from'
      editorType="dxDateBox"
      editorOptions={{
        readOnly: isReadonly
      }}
    >
      <Label text="Dal" />
      <RequiredRule />
    </SimpleItem>
  );

  const toField = (
    <SimpleItem
      dataField='to'
      editorType="dxDateBox"
      editorOptions={{
        readOnly: isReadonly
      }}
    >
      <Label text="Al" />
      <RequiredRule />
    </SimpleItem>
  );

  const dataField = (
    <SimpleItem
      dataField='data'
      editorType="dxDateBox"
      editorOptions={{
        readOnly: isReadonly
      }}
    >
      <Label text="Data" />
      <RequiredRule />
    </SimpleItem>
  );

  return <EntityEditPage
    domain='Book'
    entity='tipoCameraDisponibilita'
    onDatarowReady={onDatarowReady}
    customNewCommandValue='BulkNew'
    customSaveCommandValue={customSaveCommandValue}
  >
    <EntityEditPage.EditForm>
      {(datarow) => (
        <React.Fragment>
          {isRangeModel && fromField}
          {isRangeModel && toField}
          {!isRangeModel && dataField}
          <SimpleItem
            dataField='tipoCameraAzienda'
            editorType='dxSelectBox'
            editorOptions={{
              ...tipoCameraAziendaSelectBoxConfig,
              readOnly: isReadonly
            }}>
              <Label text='Tipo Camera' />
              <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField='num_camere'
            editorType='dxNumberBox'>
            <Label text='Num. libere' />
          </SimpleItem>
        </React.Fragment>
      )}
    </EntityEditPage.EditForm>
  </EntityEditPage>
}