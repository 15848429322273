import React, { useMemo } from 'react';

import { Column, Lookup, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage, useSvcLookupDataGridColumnStore } from '@/svc';

export default function CorsiPage() {
  const contattiDatasource = useSvcLookupDataGridColumnStore({
    domain: 'Core',
    entity: 'contatti'
  });

  const clientiDatasource = useSvcLookupDataGridColumnStore({
    domain: 'Core',
    entity: 'clienti'
  });

  const clienteLabel = process.env.REACT_APP_ENTITY_CLIENTE_ALIAS ?? 'Cliente';

  // Attenzione: è intenzionale esternalizzare i filtri e non lasciarli inline
  // in quanto se si facesse diversamente, ogni cambiamento allo state
  // implicherebbe un rerender di tutto l'albero, incluso il box dei filtri
  // che tornerebbe al suo state iniziale.
  const filtersBox = useMemo(() => <EntityListPage.Filters
    clientiRecord={{
      initialValue: null
    }}
    maxEntries={{
      initialValue: 100
    }}
    corsiRecord={{
      initialValue: null
    }}
    giorni
  />, []);
  
  return (
    <EntityListPage
      domain='core'
      entity='contattiCorsi'
      editRoute='/core/ContattiCorsi/edit'>
        {filtersBox}
        <EntityListPage.Columns>
        <Column
            caption={clienteLabel}
            dataField='clientiRecord.id'>
              <Lookup
                dataSource={clientiDatasource}
                displayExpr="displayText"
                valueExpr="id" />
          </Column>
          <Column
            caption='Contatto'
            dataField='contatto.id'>
              <Lookup
                dataSource={contattiDatasource}
                displayExpr="displayText"
                valueExpr="id" />
              <RequiredRule />
          </Column>
          <Column
            caption='Corso'
            dataField='corso.displayText'
            dataType='string' />
          <Column
            caption='Data Inizio'
            dataField='data_inizio'
            dataType='date' />
          <Column
            caption='Data Fine'
            dataField='data_fine'
            dataType='date' />
          <Column
            caption='Data Scadenza'
            dataField='data_scadenza'
            dataType='date' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}