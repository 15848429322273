import { useMemo } from 'react';

export function useEnv() {
  const envMemo = useMemo(() => {
    const { 
      REACT_APP_LOGO,
      REACT_APP_USE_CLIENTI_CATEGORIE_FLAG_IMPORTANTE,
      REACT_APP_USE_CLIENTI_CATEGORIE_TREELIST,
      REACT_APP_USE_CLIENTI_CATEGORIE_CODICE_GESTIONALE
    } = process.env;

    return {
      logo: REACT_APP_LOGO,
      clientiCategorieUseFlagImportante: REACT_APP_USE_CLIENTI_CATEGORIE_FLAG_IMPORTANTE === '1',
      clientiCategorieUseTreelist: REACT_APP_USE_CLIENTI_CATEGORIE_TREELIST === '1',
      clientiCategorieUseCodiceGestionale: REACT_APP_USE_CLIENTI_CATEGORIE_CODICE_GESTIONALE === '1'
    };
  }, []);

  return envMemo;
}
