import React, { useMemo } from 'react';

import { SimpleItem, Label } from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/data-grid';

import { EntityEditPage, useSvcSelectBoxConfig } from '@/svc';

export default function UtentiEditPage() {
  const displayAzienda = process.env.REACT_APP_USE_MULTI_AZIENDA === '1';

  const passwordTextBoxConfig = useMemo(() => ({
    mode: 'password'
  }), []);

  const aziendeSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Auth',
    entity: 'aziende',
    displayExpr: 'ragionesociale',
    lookupKey: 'id'
  });
  const livelliSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Auth',
    entity: 'livelli',
    displayExpr: 'descrizione',
    lookupKey: 'id'
  });
  /*
  const authRuoliSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Auth',
    entity: 'authruoli',
    displayExpr: 'descrizione',
    lookupKey: 'id'
  });
  */

  return <EntityEditPage
    domain='Auth'
    entity='utenti'
  >
    <EntityEditPage.EditForm>
      {(datarow) => (
        <React.Fragment>
          <SimpleItem dataField="nome" editorType="dxTextBox">
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField="username" editorType="dxTextBox">
            <RequiredRule />
          </SimpleItem>
          {!datarow.id && (
            <SimpleItem dataField="password" editorType="dxTextBox" editorOptions={passwordTextBoxConfig}>
              <RequiredRule />
            </SimpleItem>
          )}
          <SimpleItem visible={displayAzienda} dataField="authAziendeRecord" editorType="dxSelectBox" editorOptions={aziendeSelectBoxConfig}>
            <Label text="Azienda" />
            <RequiredRule />
          </SimpleItem>
          {/*
          <SimpleItem dataField="authRuoliRecord" editorType="dxSelectBox" editorOptions={authRuoliSelectBoxConfig}>
            <Label text="Ruolo" />
            <RequiredRule />
          </SimpleItem>
          */}
          <SimpleItem dataField="livelloRecord" editorType="dxSelectBox" editorOptions={livelliSelectBoxConfig}>
            <Label text="Livello" />
            <RequiredRule />
          </SimpleItem>
        </React.Fragment>
      )}
    </EntityEditPage.EditForm>
  </EntityEditPage>
}