import { useCallback, useContext } from 'react';

import { SvcContext } from '@/svc';

export default function useSvcCustomRequest(domain, entity) {
  const { client, user } = useContext(SvcContext);

  const token = user?.token_login;

  const customRequest = useCallback(
    (body) => client.customRequest(domain, entity, body, { token }),
    [ client, domain, entity, token ]
  );

  return { customRequest };
}