import React from 'react';

import { Column, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function TipoCameraPage() {
  return <EntityListPage
    domain='Book'
    entity='tipoCamera'
    editRoute='/book/TipoCamera/edit'
  >
    <EntityListPage.Columns>
      <Column
        caption='Data Creazione'
        dataField='data_creazione'
        dataType='datetime'>
      </Column>
      <Column
        caption='Ultimo Aggiornamento'
        dataField='last_update'
        dataType='datetime'>
      </Column>
      <Column
        caption='Nome'
        dataField='nome'
        dataType='string'>
          <RequiredRule />
      </Column>
      <Column
        caption='Codice Esterno'
        dataField='codice_gest_esterno'
        dataType='string'>
          <RequiredRule />
      </Column>
    </EntityListPage.Columns>
  </EntityListPage>
}