import React, { useContext, useEffect } from 'react';

import { NavContext } from '@/navigation';
import {
  AziendePage,
  AziendeEditPage,
  ClientiPage,
  ClientiEditPage,
  ClientiCategoriePage,
  ClientiCategorieEditPage,
  ContattiClientiDatiPage,
  ContattiCorsiEditPage,
  ContattiCorsiPage,
  CorsiEditPage,
  CorsiPage,
  CorsiScadenzePage,
  CorsiTipologiaPage,
  CorsiTipologiaEditPage,
  ListinoPeriodiEditPage,
  ListinoPeriodiPage,
  ListinoTipoCameraAziendaEditPage,
  ListinoTipoCameraAziendaPage,
  NomineOrganigrammaEditPage,
  NomineOrganigrammaPage,
  OrganigrammaEditPage,
  OrganigrammaPage,
  PrenotazioniEditPage,
  PrenotazioniPage,
  RiepilogoPrenotazioniPage,
  TipoCameraAziendaEditPage,
  TipoCameraAziendaPage,
  TipoCameraDisponibilitaPage,
  TipoCameraDisponibilitaEditPage,
  TipoCameraEditPage,
  TipoCameraPage,
  ServiziPrenotazionePage,
  ServiziPrenotazioneEditPage,
  ServiziPrenotazioneAziendeEditPage,
  ServiziPrenotazioneAziendePage,
  UtentiEditPage,
  UtentiPage,
  ContattiPage,
  ContattiEditPage,
  BookAziendePage,
  BookAziendeEditPage,
  DateFormazionePage,
  DateFormazioneEditPage,
  PermessiPescaEditPage,
  PermessiPescaPage,
  FormazioneCorsiPage,
  FormazioneCorsiEditPage,
  PeriodiImpostazioniPage,
  PeriodiImpostazioniEditPage
} from '@/ui/pages';

const routes = [
  {
    path: '/auth/Aziende',
    component: AziendePage
  },
  {
    path: '/auth/Aziende/edit/:key?',
    component: AziendeEditPage
  },
  {
    path: '/book/BookAziende',
    component: BookAziendePage
  },
  {
    path: '/auth/BookAziende/edit/:key?',
    component: BookAziendeEditPage
  },
  {
    path: '/auth/Utenti',
    component: UtentiPage
  },
  {
    path: '/auth/Utenti/edit/',
    component: UtentiEditPage
  },
  {
    path: '/auth/Utenti/edit/:key?',
    component: UtentiEditPage
  },
  {
    path: '/book/Prenotazioni',
    component: PrenotazioniPage
  },
  {
    path: '/book/ListinoPeriodi',
    component: ListinoPeriodiPage
  },
  {
    path: '/book/ListinoPeriodi/edit/:key?',
    component: ListinoPeriodiEditPage
  },
  {
    path: '/book/ListinoTipoCameraAzienda/edit/:key?',
    component: ListinoTipoCameraAziendaEditPage
  },
  {
    path: '/book/ListinoTipoCameraAzienda',
    component: ListinoTipoCameraAziendaPage
  },
  {
    path: '/hres/NomineOrganigramma/edit/:key?',
    component: NomineOrganigrammaEditPage
  },
  {
    path: '/hres/NomineOrganigramma',
    component: NomineOrganigrammaPage
  },
  {
    path: '/hres/Organigramma/edit/:key?',
    component: OrganigrammaEditPage
  },
  {
    path: '/hres/Organigramma',
    component: OrganigrammaPage
  },
  {
    path: '/book/PeriodiImpostazioni',
    component: PeriodiImpostazioniPage
  },
  {
    path: '/book/PeriodiImpostazioni/edit/:key?',
    component: PeriodiImpostazioniEditPage
  },
  {
    path: '/book/Prenotazioni/edit/:key?',
    component: PrenotazioniEditPage
  },
  {
    path: '/book/RiepilogoPrenotazioni',
    component: RiepilogoPrenotazioniPage
  },
  {
    path: '/book/ServiziPrenotazione',
    component: ServiziPrenotazionePage
  },
  {
    path: '/book/ServiziPrenotazione/edit/:key?',
    component: ServiziPrenotazioneEditPage
  },
  {
    path: '/book/ServiziPrenotazioneAziende',
    component: ServiziPrenotazioneAziendePage
  },
  {
    path: '/book/ServiziPrenotazioneAziende/edit/:key?',
    component: ServiziPrenotazioneAziendeEditPage
  },
  {
    path: '/book/TipoCamera',
    component: TipoCameraPage
  },
  {
    path: '/book/TipoCamera/edit/:key?',
    component: TipoCameraEditPage
  },
  {
    path: '/book/TipoCameraAzienda',
    component: TipoCameraAziendaPage
  },
  {
    path: '/book/TipoCameraAzienda/edit/:key?',
    component: TipoCameraAziendaEditPage
  },
  {
    path: '/book/TipoCameraDisponibilita',
    component: TipoCameraDisponibilitaPage
  },
  {
    path: '/book/TipoCameraDisponibilita/edit/:key?',
    component: TipoCameraDisponibilitaEditPage
  },
  {
    path: '/core/Clienti',
    component: ClientiPage
  },
  {
    path: '/core/Clienti/edit/:key?',
    component: ClientiEditPage
  },
  {
    path: '/core/ClientiCategorie',
    component: ClientiCategoriePage
  },
  {
    path: '/core/ClientiCategorie/edit/:key?',
    component: ClientiCategorieEditPage
  },
  {
    path: '/core/Contatto',
    component: ContattiPage
  },
  {
    path: '/core/Contatto/edit/:key?',
    component: ContattiEditPage
  },
  {
    path: '/core/ContattiCorsi/edit/:key?',
    component: ContattiCorsiEditPage
  },
  {
    path: '/core/ContattiClientiDati',
    component: ContattiClientiDatiPage
  },
  {
    path: '/core/ContattiCorsi',
    component: ContattiCorsiPage
  },
  {
    path: '/xtra/Corsi/edit/:key?',
    component: CorsiEditPage
  },
  {
    path: '/xtra/Corsi',
    component: CorsiPage
  },
  {
    path: '/xtra/CorsiTipologia',
    component: CorsiTipologiaPage
  },
  {
    path: '/xtra/CorsiTipologia/edit/:key?',
    component: CorsiTipologiaEditPage
  },
  {
    path: '/xtra/Corsi/Scadenze',
    component: CorsiScadenzePage
  },
  {
    path: '/xtra/FormazioneCorsi',
    component: FormazioneCorsiPage
  },
  {
    path: '/xtra/FormazioneCorsi/edit/:key?',
    component: FormazioneCorsiEditPage
  },
  {
    path: '/xtra/DateFormazione',
    component: DateFormazionePage
  },
  {
    path: '/xtra/DateFormazione/edit/:key?',
    component: DateFormazioneEditPage
  },
  {
    path: '/store/PermessiPesca',
    component: PermessiPescaPage
  },
  {
    path: '/store/PermessiPesca/edit/:key?',
    component: PermessiPescaEditPage
  }
];

function withNavigationWatcher(Component) {
  return function (props) {
    const { path } = props.match;
    const { setNavData } = useContext(NavContext);

    useEffect(() => {
      setNavData({ currentPath: path });
    }, [ path, setNavData ]);

    return React.createElement(Component, props);
  }
}

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
