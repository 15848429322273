import { useCallback, useContext } from 'react';

import { SvcContext } from '@/svc';

export default function useSvcDownloadFile() {
  const { client } = useContext(SvcContext);

  const downloadFile = useCallback((filename) => {
    return client.downloadFile(filename);
  }, [ client ]);

  return downloadFile;
}