import React, { useMemo } from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useAuth, useSvcDatasource } from '@/svc';


export default function TipoCameraAziendaEditPage() {
  const { user } = useAuth();
  const aziendeDatasource = useSvcDatasource({
    domain: 'Auth',
    entity: 'aziende'
  });

  const tipoCameraDatasource = useSvcDatasource({
    domain: 'Book',
    entity: 'tipoCamera'
  });

  const isUserAdmin = useMemo(() => user.IsAdmin === true, [user]);

  return <EntityEditPage
    domain='Book'
    entity='tipoCameraAzienda'
  >
    <EntityEditPage.EditForm>
      {(datarow) => (
        <React.Fragment>
          <SimpleItem 
            dataField='azienda' 
            editorType='dxSelectBox'
            visible={isUserAdmin} 
            editorOptions={{
              dataSource: aziendeDatasource,
              displayExpr: 'ragionesociale'
            }}>
            <Label text='Azienda' />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField='min_persone' editorType='dxNumberBox'>
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField='max_persone' editorType='dxNumberBox'>
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField='num_camere' editorType='dxNumberBox'>
            <RequiredRule />
          </SimpleItem>
          <SimpleItem 
            dataField='tipoCamera' 
            editorType='dxSelectBox' 
            editorOptions={{
              dataSource: tipoCameraDatasource,
              displayExpr: 'displayText',
              readOnly: isUserAdmin === false
            }}>
            <Label text='Tipo Camera' />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            visible={isUserAdmin} 
            dataField='tipoListino' 
            editorType='dxSelectBox' 
            editorOptions={{
              dataSource: [{id: 10, nome: 'Con Riduzioni'}, {id: 20, nome: 'Prezzi Assoluti'}, {id: 30, nome: 'Prezzo Unitario'}],
              displayExpr: 'nome',
              valueExpr: 'id'
            }}>
            <Label text='Tipo Listino' />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            visible={isUserAdmin}
            dataField='wp_product_id' 
            editorType='dxNumberBox'>
            <Label text='ID Prodotto Wordpress' />
          </SimpleItem>
          <SimpleItem
            visible={isUserAdmin}
            dataField='id_sync' 
            editorType='dxTextBox'>
            <Label text='ID Per gestionali esterni' />
          </SimpleItem>          
        </React.Fragment>
      )}
    </EntityEditPage.EditForm>
  </EntityEditPage>
}