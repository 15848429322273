import React from 'react';

import { SvcSelectBox } from '@/svc/components';

export default function ClientiRecordFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  const onValueChanged = ({value}) => {
    setValue(value);
  }

  return (
    <SvcSelectBox
      domain='Core'
      entity='clienti'
      value={value}
      setValue={onValueChanged}
    />
  );
}

ClientiRecordFilter.key = 'clientiRecord';
ClientiRecordFilter.label = 'Azienda';