import React from 'react';

import { EmptyItem, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcAziendaType, useSvcDatasource, useSvcSelectBoxConfig } from '@/svc';

export default function PrenotazioniEditPage() {
  const serviziPrenotazioneAziendeDatasource = useSvcDatasource({
    domain: 'Book',
    entity: 'serviziPrenotazioneAziende',
    lookupKey: 'id'
  });
  const tipoCameraAziendaDatasource = useSvcDatasource({
    domain: 'Book',
    entity: 'tipoCameraAzienda',
    lookupKey: 'id'
  });
  const tipoPrenotazioneDatasource = useSvcDatasource({
    domain: 'Book',
    entity: 'tipoPrenotazione',
    lookupKey: 'id'
  });

  const { isServizio } = useSvcAziendaType();

  return (
    <EntityEditPage
      domain='Book'
      entity='prenotazioni'>
        <EntityEditPage.EditForm>
          {(datarow) => (
            <React.Fragment>
              <SimpleItem
                dataField={isServizio ? 'servizioAzienda' : 'tipoCameraAzienda'}
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: isServizio
                    ? serviziPrenotazioneAziendeDatasource
                    : tipoCameraAziendaDatasource,
                  displayExpr: isServizio ? 'servizio.displayText' : 'tipoCamera.displayText',
                  readOnly: datarow.id > 0
                }}>
                  <Label text={isServizio ? 'Servizio' : 'Tipo Camera'} />
                  <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField='prima_notte' editorType='dxDateBox' editorOptions={{
                readOnly: datarow.tipoPrenotazione?.id === 1
              }}>
                <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField='ultima_notte' editorType='dxDateBox' editorOptions={{
                readOnly: datarow.tipoPrenotazione?.id === 1
              }}>
                <RequiredRule />
              </SimpleItem>
              <EmptyItem colSpan={1} />
              <SimpleItem
                dataField='statoPrenotazione.descrizione'
                editorType='dxTextBox'
                editorOptions={{
                  readOnly: true
                }}>
                  <Label text='Stato Prenotazione' />
                  <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='tipoPrenotazione'
                editorType='dxSelectBox' 
                editorOptions={{
                  dataSource: tipoPrenotazioneDatasource,
                  displayExpr: 'displayText',
                  readOnly: true
                }}>
                  <Label text='Tipo Prenotazione' />
                  <RequiredRule />
              </SimpleItem>
              <EmptyItem colSpan={2} />
              <SimpleItem
                dataField='riferimento'
                editorType='dxTextBox'
                colSpan={4}>
                  <Label text='Descrizione' />
                  <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='infoComposizione.adulti'
                editorType='dxNumberBox'
                editorOptions={{
                  readOnly: datarow.tipoPrenotazione?.id === 1
                }}>
                  <Label text='Numero di adulti' />
              </SimpleItem>
              <SimpleItem
                dataField='infoComposizione.bambini_omaggio'
                editorType='dxNumberBox'
                editorOptions={{
                  readOnly: datarow.tipoPrenotazione?.id === 1
                }}>
                  <Label text='Numero di bambini omaggio' />
              </SimpleItem>
              <SimpleItem
                dataField='infoComposizione.bambini'
                editorType='dxNumberBox'
                editorOptions={{
                  readOnly: datarow.tipoPrenotazione?.id === 1
                }}>
                  <Label text='Numero di bambini' />
              </SimpleItem>
              <SimpleItem
                dataField='infoComposizione.ragazzi'
                editorType='dxNumberBox'
                editorOptions={{
                  readOnly: datarow.tipoPrenotazione?.id === 1
                }}>
                  <Label text='Numero di ragazzi' />
              </SimpleItem>
              <SimpleItem
                dataField='infoComposizione.persone'
                editorType='dxNumberBox'
                editorOptions={{
                  readOnly: datarow.tipoPrenotazione?.id === 1
                }}>
                  <Label text={'Numero di persone (Se valorizzato '
                    + 'verranno ignorati i 4 campi precendenti)'} />
              </SimpleItem>
            </React.Fragment>
          )}
        </EntityEditPage.EditForm>
      </EntityEditPage>
    );
}