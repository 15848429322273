import React, { useContext, useMemo, useState } from 'react';

import { default as DxToolbar, Item } from 'devextreme-react/toolbar';
import { useHistory } from 'react-router-dom';

import { NavContext } from '@/navigation';
import { useSvcDownloadFile, useSvcExport } from '@/svc/hooks';

export default function Toolbar(props) {
  const { 
    title, items, editRoute,
    entity, domain,
    newEnabled = true, newButtonOptions = {},
    exportEnabled = false, exportButtonOptions = {}
  } = props;

  const history = useHistory();
  const { currentRoute } = useContext(NavContext);
  
  const svcDownloadFile = useSvcDownloadFile();
  const svcExport = useSvcExport(domain, entity);
  const [svcExportLoading, setSvcExportLoading] = useState(false);

  const stringifiedNewButtonOptions = JSON.stringify(newButtonOptions);
  const newButtonOpts = useMemo(() => ({
    icon: 'add',
    type: 'success',
    onClick: () => history.push(`${editRoute}/`),
    ...newButtonOptions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [ editRoute, history, stringifiedNewButtonOptions ]);

  const stringifiedExportButtonOptions = JSON.stringify(exportButtonOptions);
  const exportButtonOpts = useMemo(() => ({
    icon: 'export',
    type: 'success',
    onClick: async () => {
      setSvcExportLoading(true);
      const [fileUrl, error] = await svcExport();
      setSvcExportLoading(false);

      if (error) {
        alert(error);
      }
      else {
        svcDownloadFile(fileUrl);
      }
    },
    ...exportButtonOptions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [ stringifiedExportButtonOptions ]);

  return (
    <DxToolbar style={{ backgroundColor: 'transparent' }}>
      <Item 
        location="before"
        locateInMenu="never"
        render={() => <div className="toolbar-label">{title}</div>} />
      {!currentRoute.readOnly && <Item 
        visible={newEnabled}
        location="after"
        locateInMenu="never"
        widget="dxButton"
        options={newButtonOpts}
      />}
      {exportEnabled && <Item 
        location="after"
        locateInMenu="never"
        widget="dxButton"
        options={{
          ...exportButtonOpts,
          disabled: svcExportLoading,
          icon: svcExportLoading ? 'fa fa-spinner fa-spin' : exportButtonOpts.icon
        }}
      />}
      {items}
    </DxToolbar>
  );
}