import React from 'react';

import { Column } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function DateFormazionePage() {
  return (
    <EntityListPage
      domain='Xtra'
      entity='dateFormazione'
      editRoute='/xtra/DateFormazione/edit'>
        <EntityListPage.Columns>
          <Column
            caption='Data Inizio'
            dataField='data_inizio'
            dataType='datetime' />
          <Column
            caption='Oggetto'
            dataField='oggetto'
            dataType='string' />
          <Column
            caption='Note'
            dataField='note'
            dataType='string' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}