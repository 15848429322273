import React from 'react';

import { Column, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';
import { useEnv } from '@/ui/hooks';

export default function ClientiCategoriePage() {
  const { 
    clientiCategorieUseFlagImportante,
    clientiCategorieUseTreelist,
    clientiCategorieUseCodiceGestionale
  } = useEnv();

  return (
    <EntityListPage
      domain='Core'
      entity='clientiCategorie'
      editRoute='/core/ClientiCategorie/edit'
      listComponent={clientiCategorieUseTreelist
          ? EntityListPage.TreeList
          : EntityListPage.DataGridList}
    >
      <EntityListPage.Columns>
        <Column
          caption='Codice Gestionale'
          dataField='codice_gestionale'
          dataType='string'
          visible={clientiCategorieUseCodiceGestionale}>
            <RequiredRule />
        </Column>
        <Column
          caption='Nome'
          dataField='descrizione'
          dataType='string'>
            <RequiredRule />
        </Column>
        <Column
          caption='Importante'
          dataField='flag_importante'
          dataType='boolean'
          visible={clientiCategorieUseFlagImportante}>
            <RequiredRule />
        </Column>
      </EntityListPage.Columns>
    </EntityListPage>
  );
}