import React from 'react';

import { Column } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function ContattiClientiDatiPage() {  
  return (
    <EntityListPage
      domain='core'
      entity='contattiClientiDati'
      newEnabled={false}
      >
        <EntityListPage.Columns>
          <Column
            caption={'Nome e cognome'}
            dataField='contatto.displayText'
            dataType='string' />
          <Column
            caption={'Azienda'}
            dataField='clientiRecord.displayText'
            dataType='string' />
          <Column
            caption='Data inizio rapporto'
            dataField='data_assunzione'
            dataType='date' />
          <Column
            caption='Data fine rapporto'
            dataField='data_licenziamento'
            dataType='date' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}