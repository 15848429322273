import './error.scss';

import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import { NavContext } from '@/navigation';
import WarningSign from './warning.svg';

export default function ErrorPage({ message }) {
  const { homeRoute } = useContext(NavContext);

  return (
    <div className='dx-card alert-container'>
      <img src={WarningSign} className='alert-svg' alt='Warning Sign' />

      <p className='alert-message'>{message}</p>

      <Link to={homeRoute} className='alert-button'>Torna alla Home</Link>
    </div>
  );
}