// Be aware of the import order: it can causes unwanted CSS rules overrides.
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';
import './dx-styles.scss';
import './styles.css';

import React from 'react';

import LoadPanel from 'devextreme-react/load-panel';

import { useAuth } from '@/svc';
import { useScreenSizeClass } from '@/ui/hooks';
import { AuthedLayout, NotAuthedLayout } from '@/ui/layouts';

export default function UI() {
  const { user, loading } = useAuth();
  const screenSizeClass = useScreenSizeClass();

  let layout;

  if (loading) {
    layout = <LoadPanel visible />;
  } else if (user) {
    layout = <AuthedLayout />;
  } else {
    layout = <NotAuthedLayout />;
  }

  return (
    <div className={`app ${screenSizeClass}`}>
      {layout}
    </div>
  );
}