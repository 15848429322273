import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { NavContext } from '@/navigation';
import { useSvcFilters } from '@/svc/hooks';
import { ErrorPage } from '@/ui/pages';

import { FiltersRenderer, CardFilters } from './filters';
import { ListRenderer, DataGridList, TreeList } from './list';
import Toolbar from './toolbar';

const EntityListPage = forwardRef(_EntityListPage);
function _EntityListPage(props, ref) {
  const {
    domain, entity, editRoute, dataSourceListOpts,
    onInit, onLoad, onEdit, onClone,
    filtersComponent, listComponent,
    primaryKey = 'id', parentIdExpr = 'padre.id', filtersResponseKey,
    newEnabled = true, newButtonOptions = {},
    selectionMode = 'none', onSelectionChanged,
    children
  } = props;

  const listRendererRef = useRef();
  useImperativeHandle(ref, () => ({
    listRendererRef: listRendererRef,
    clearSelection: () => {
      listRendererRef.current.clearSelection();
    }
  }));
  
  const { entityTitleMap } = useContext(NavContext);
  const { loading, error, filters } = useSvcFilters(
    domain, entity, {}, filtersResponseKey
  );

  const [mergedFilters, setMergedFilters] = useState(void 0);

  const updateMergedFilters = (newFilters) => setMergedFilters(newFilters);

  const sessionStorageFiltersKey = `${entity}-page-filters`;

  const {
    columns,
    filters: filtersItems,
    rowButtons,
    toolbarItems
  } = parseChildren(children);

  useEffect(() => {
    if (filtersItems) {
      const sessionStorageFiltersValue = window.sessionStorage.getItem(
        sessionStorageFiltersKey
      );
      let parsedSessionStorageFilters = {};
      if (sessionStorageFiltersValue) {
        try {
          parsedSessionStorageFilters = JSON.parse(sessionStorageFiltersValue);
        } catch (e) {
          window.sessionStorage.removeItem(sessionStorageFiltersKey);
        }
      }

      const initialFiltersValues = Object.entries(filtersItems)
        .reduce(
          (acc, [key, {initialValue = void 0}]) => {
            if (initialValue) {
              acc[key] = initialValue;
            }
            return acc;
          }, {}
        );

      const _mergedFilters = {
        ...(filters ?? {}),
        ...(initialFiltersValues),
        ...parsedSessionStorageFilters
      };
      setMergedFilters(_mergedFilters);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtersItems,
    filters
  ]);

  useEffect(() => {
    window.sessionStorage.setItem(sessionStorageFiltersKey, JSON.stringify(mergedFilters));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedFilters]);

  if (loading) {
    // TODO: Abbellire sto schifo
    return <h5>Caricamento Filtri...</h5>;
  } else if (error) {
    return <ErrorPage message={`Errore nel caricamento dei filtri: ${error}`} />;
  } else {
    return (
      <>
        <div className='content-header'>
          <Toolbar
            domain={domain}
            entity={entity}
            title={<span style={{fontSize: '2.5rem'}}>{entityTitleMap[entity] || ''}</span>}
            editRoute={editRoute}
            items={toolbarItems}
            newEnabled={newEnabled}
            newButtonOptions={newButtonOptions}
            exportEnabled={mergedFilters?.entityIsExportable}
          />
        </div>
        
        {filtersItems && 
          <FiltersRenderer
            component={filtersComponent || CardFilters}
            filters={mergedFilters}
            items={filtersItems}
            updateFilters={updateMergedFilters}
          />
        }

        <ListRenderer
          ref={listRendererRef}
          component={listComponent || DataGridList}
          domain={domain}
          entity={entity}
          filters={mergedFilters}
          columns={columns}
          rowButtons={rowButtons}
          editRoute={editRoute}
          primaryKey={primaryKey}
          parentIdExpr={parentIdExpr}
          selectionMode={selectionMode}
          onInit={onInit}
          onLoad={onLoad}
          onEdit={onEdit}
          onClone={onClone}
          onSelectionChanged={onSelectionChanged}
          dataSourceListOpts={dataSourceListOpts}
        />
      </>
    );
  }
}

function parseChildren(children) {
  const childrenArr = React.Children.toArray(children);

  const columns = childrenArr
    .find(comp => comp.type === EntityListPage.Columns)
    ?.props?.children;
  const filters = childrenArr
    .find(comp => comp.type === EntityListPage.Filters)
    ?.props;
  const rowButtons = childrenArr
    .find(comp => comp.type === EntityListPage.RowButtons)
    ?.props?.children;
  const toolbarItems = childrenArr
    .find(comp => comp.type === EntityListPage.ToolbarItems)
    ?.props?.children;

  return { columns, filters, rowButtons, toolbarItems };
}

EntityListPage.Columns = ({ children }) => null;
EntityListPage.Filters = ({ maxEntries }) => null;
EntityListPage.RowButtons = ({ children }) => null;
EntityListPage.ToolbarItems = ({ children }) => null;

EntityListPage.DataGridList = DataGridList;
EntityListPage.TreeList = TreeList;

EntityListPage.CardFilters = CardFilters;

export default EntityListPage;
