import { useCallback, useContext } from 'react';

import { NavContext } from '@/navigation';
import { SvcContext, useSvcCustomRequest } from '@/svc';

export default function useAuth() {
  const { setNavTree } = useContext(NavContext);
  const { client, loading, user, setUser, setAziendaAs } = useContext(SvcContext);
  const { customRequest } = useSvcCustomRequest('Auth', 'utenti');

  const signIn = useCallback(async (username, password) => {
    const res = await client.logIn(username, password);
    if (!res.utente) {
      return;
    }

    //const tree = await client.fetchNavTree(res.utente.token_login);
    const newNavTree = JSON.parse(JSON.stringify(
      res.utente.menuList
    ));

    setNavTree(newNavTree);
    setUser(res.utente);
  }, [ client, setNavTree, setUser ]);

  const signOut = useCallback(async () => {
    await client.logOut(user.token_login);
    setUser();
    setAziendaAs();
  }, [ client, user, setUser ]);

  const changePassword = useCallback((oldPassword, newPassword, confirmNewPassword) => {
    return customRequest({
      action: 'authChangePassword',
      datiLogin: {
        password: oldPassword,
        nuova_password: newPassword,
        nuova_password_verify: confirmNewPassword
      }
    });
  }, [customRequest]);

  return { user, loading, signIn, signOut, changePassword };
}