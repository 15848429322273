import React, { useCallback, useMemo, useRef, useState } from 'react';

import shortid from 'shortid';
import { DateTime } from 'luxon';
import { Label, SimpleItem, RequiredRule, EmptyItem } from 'devextreme-react/form';

import { EntityEditPage, EntityFormInlineList, useSvcGet, useSvcSelectBoxConfig, SvcSmsTextBox } from '@/svc';
import { Button, CheckBox, Form, List, LoadIndicator } from 'devextreme-react';

import { FlagsListComponent, Modal } from '@/ui/components';
import { CorsiScadenzePage } from '..';
import { Toast } from '@/ui/utils';

const clientiAlias = (process.env.REACT_APP_ENTITY_CLIENTI_ALIAS || 'Clienti');
const clienteAlias = (process.env.REACT_APP_ENTITY_CLIENTE_ALIAS || 'Cliente');

export default function ContattiEditPage() {
  const [displayScadenzeBox, setDisplayScadenzeBox] = useState(false);
  const [currentDatarow, setCurrentDatarow] = useState(null);

  let filtriSelectComuni = void 0;

  // check filtri standard
  const filtriDefault = process.env.REACT_APP_PAGE_CLIENTI_COMUNI_DEFAULT_FILTER;
  if (filtriDefault) {
    filtriSelectComuni = JSON.parse(filtriDefault);
  }

  const onDatarowReady = (datarow) => {
    if (datarow.id > 0) setDisplayScadenzeBox(true);
    setCurrentDatarow(datarow);
  }

  const comuniSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Core',
    entity: 'comuni',
    paginate: true,
    filters: filtriSelectComuni
  });

  const clientiSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Core',
    entity: 'clienti',
    paginate: true
  });

  return <EntityEditPage
    domain='Core'
    entity='contatti'
    onDatarowReady={onDatarowReady}>
      <EntityEditPage.EditForm>
        {(datarow, setDatarowField) => {
          return (
            <React.Fragment>
              <SimpleItem dataField="nome" editorType="dxTextBox">
                <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField="cognome" editorType="dxTextBox">
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField="sesso"
                editorType="dxSelectBox"
                editorOptions={{
                  valueExpr: 'id',
                  displayExpr: 'descrizione',
                  dataSource: [{
                    id: 'M',
                    descrizione: 'Maschio'
                  }, {
                    id: 'F',
                    descrizione: 'Femmina'
                  }]
                }}>
              </SimpleItem>
              <EmptyItem colSpan={1} />
              <SimpleItem
                colSpan={2}
                dataField="email"
                editorType="dxTextBox" />
              <SimpleItem dataField="codice_fiscale" editorType="dxTextBox" />
              <SimpleItem dataField="data_nascita" editorType="dxDateBox" />
              <SimpleItem
                colSpan={2}
                dataField="indirizzo"
                editorType="dxTextBox" />
              <SimpleItem dataField="cap" editorType="dxTextBox" />
              <EmptyItem colSpan={1} />
              <SimpleItem
                colSpan={2}
                dataField="comune"
                editorType="dxSelectBox"
                editorOptions={comuniSelectBoxConfig}>
                  <Label text="Località" />
              </SimpleItem>
              <SimpleItem dataField="provincia" editorType="dxTextBox" />
              <EmptyItem colSpan={1} />
              <SimpleItem dataField="telefono" editorType="dxTextBox" />
              
              {/*<SimpleItem dataField="sms" editorType="dxTextBox" />*/}
              <SimpleItem>
                <Label text="Cellulare" />
                <SvcSmsTextBox
                  value={datarow.sms}
                  setValue={setDatarowField('sms')}
                  domain='Core'
                  entity='contatti'
                />
              </SimpleItem>

              <EmptyItem colSpan={2} />
              <SimpleItem colSpan={4}>
                <Label text="Lista Proprietà" />
                <FlagsListComponent
                  items={datarow.flagsList}
                  setItems={setDatarowField('flagsList')}
                />
              </SimpleItem>
              <SimpleItem colSpan={4}>
                <Label text={clientiAlias} />
                <EntityFormInlineList
                  items={datarow.clientiDatiList}
                  setItems={setDatarowField('clientiDatiList')}
                  domain='Core'
                  entity='contattiClientiDati'
                  formTitle='Aggiungi / modifica collegamento'
                  defaultNewData={{
                    contatto: datarow.id > 0 ? {
                      id: datarow.id,
                      _isKeyReference: true
                    } : datarow
                  }}
                  ListItemComponent={(item) => <span>
                    {item.displayText ?? item.clientiRecord?.displayText}
                    {!item.data_assunzione && <span> - (Data inizio rapporto non indicata)</span>}
                    {!item.data_licenziamento && <span> - (Data licenziamento non indicata)</span>}
                    {item.data_assunzione && <span> - Inizio rapporto: {DateTime.fromISO(item.data_assunzione).toLocaleString(DateTime.SHORT)}</span>}
                    {item.data_licenziamento && <span> - Fine rapporto: {DateTime.fromISO(item.data_licenziamento).toLocaleString(DateTime.SHORT)}</span>}
                  </span>}
                  FormItems={[
                    <SimpleItem
                      itemLabel={clienteAlias}
                      dataField='clientiRecord'
                      editorType='dxSelectBox'
                      editorOptions={{...clientiSelectBoxConfig}}
                    />,
                    <SimpleItem
                      itemLabel='Data inizio rapporto'
                      dataField='data_assunzione'
                      editorType='dxDateBox'
                      editorOptions={{
                        type: 'date',
                        dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ssZ'
                      }}
                    />,
                    <SimpleItem
                      itemLabel='Data fine rapporto'
                      dataField='data_licenziamento'
                      editorType='dxDateBox'
                      editorOptions={{
                        type: 'date',
                        dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ssZ'
                      }}
                    />
                  ]}
                />
              </SimpleItem>
              {datarow.id > 0 && 
              <SimpleItem colSpan={4}>
                <Label text="Storico Tesseramenti" />
                <StoricoTesseramentiListComponent 
                  items={datarow.tesseramentiList}
                  setItems={setDatarowField('tesseramentiList')}
                />
              </SimpleItem>
              }
              <SimpleItem
                colSpan={4}
                dataField="note"
                editorType="dxTextArea" 
              />
            </React.Fragment>
          );
        }}
      </EntityEditPage.EditForm>
      {displayScadenzeBox && 
        <div className='dx-card form-card'>
          <CorsiScadenzePage
            filtri={{
              contattiRecord: currentDatarow
            }}
          />
        </div>
      }
  </EntityEditPage>
}

function AddTesseramentoButton(props) {
  const { 
    onModalClosed,
    contatto
  } = props;
  const [ modalVisible, setModalVisible ] = useState(false);
  const [{ loading, error, data }] = useSvcGet('Core', 'tesseramenti');
  const [datarow, setDatarow] = useState(null);
  const setDatarowField = useCallback((fieldName) => (fieldValue) => setDatarow({
    ...datarow,
    [fieldName]: fieldValue
  }), [datarow]);

  const title = 'Aggiungi un tesseramento';

  const onAddClick = () => {
    setDatarow({
      ...JSON.parse(JSON.stringify(data)),
      contatto
    });
    setModalVisible(true);
  }
  
  const resolveModalValue = (value) => {
    setModalVisible(false);
    onModalClosed(value);
  }

  const onConfirmClick = () => {
    resolveModalValue(datarow);
  }

  const onUndoClick = () => {
    resolveModalValue(void 0);
  }

  let modalBody = void 0;

  if (loading) {
    modalBody = <LoadIndicator visible />
  }
  else if (data && !datarow) setDatarow({
    ...data,
    contatto
  });
  else if (error) {
    modalBody = <span>Si è verificato un errore, contattare l'assistenza.</span>;
  }
  else if (datarow) {
    modalBody = <React.Fragment>
        <Form
          colCount={1}
          formData={datarow}
          id="form">
          <SimpleItem dataField="anno" editorType="dxNumberBox">
            <Label text="Anno" />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField='flag_f24'
            editorType='dxCheckBox'
            editorOptions={{
              defaultValue: false
            }}
          >
            <Label text="Quota associativa su F24" />
          </SimpleItem>
          <SimpleItem
            dataField='flag_pagato'
            editorType='dxCheckBox'
            editorOptions={{
              defaultValue: false
            }}
          >
            <Label text="Pagato" />
          </SimpleItem>
        </Form>
    </React.Fragment>
  }

  return <React.Fragment>
    <Button
        icon="add"
        type="success"
        text="Aggiungi tesseramento"
        onClick={onAddClick}
    />
    <Modal
        visible={modalVisible}
        minWidth={'40vw'}
      >
      <Modal.Title>
        {title}
      </Modal.Title>
      <React.Fragment>
        {modalBody}
      </React.Fragment>
      <Modal.Button type="danger" onClick={onUndoClick}>Annulla</Modal.Button>
      <Modal.Button type="success" onClick={onConfirmClick}>Aggiungi</Modal.Button>
    </Modal>
  </React.Fragment>;
}

function StoricoTesseramentiListComponent(props) {
  const { items = [], setItems, contatto } = props;
  const listRef = useRef();

  const onModalClosed = useCallback((value) => {
    if (value) {
      items.push(value);
      setItems(items);
      listRef.current.instance.reload();
    }
  }, [items, setItems]);

  const newButton = useMemo(() => <AddTesseramentoButton
    contatto={contatto} 
    onModalClosed={onModalClosed}
  />, [contatto, onModalClosed]);

  const deleteItem = (item) => {
    item.eliminato = true;
    setItems(items);
    Toast('info', 'Il tesseramento è stato contrassegnato come eliminato e verrà definitivamente eliminato al salvataggio. Puoi ripristinarlo cliccando il pulsante "Ripristina".', 6000);
  };

  const restoreItem = (item) =>{
    item.eliminato = false;
    setItems(items);
  };

  const setItemField = (item, fieldName, fieldValue) => {
    item[fieldName] = fieldValue;
    setItems(items);
  };

  if (items) {
    function ItemTemplate(item) {
      if (!item.uuid) item.uuid = shortid();
      return (
        <div style={{textDecoration: item.eliminato ? 'line-through' : ''}} key={item.uuid}>
          <div style={{float: 'left'}}>
            <strong>Anno: {item.anno}</strong>
          </div>
          <div style={{float: 'left', marginLeft: '1rem', paddingLeft: '1rem'}}>
            <span style={{fontSize: '1rem'}}>➜</span>
            <CheckBox
              style={{paddingLeft: '1rem'}}
              value={item.flag_f24 === undefined ? false : item.flag_f24}
              onValueChanged={({value}) => setItemField(item, 'flag_f24', value)}
              text='Quota associativa su F24'
            />
            <CheckBox
              style={{paddingLeft: '1rem'}}
              value={item.flag_pagato === undefined ? false : item.flag_pagato}
              onValueChanged={({value}) => setItemField(item, 'flag_pagato', value)}
              text='Pagato'
            />
          </div>
          
          <Button
              visible={!item.eliminato}
              style={{ float: 'right' }}
              icon='trash'
              onClick={() => deleteItem(item)}
          />
          <Button
              visible={item.eliminato}
              style={{ float: 'right' }}
              icon='refresh'
              type='success'
              text='Ripristina'
              onClick={() => restoreItem(item)}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <List
          ref={listRef}
          keyExpr="uuid"
          dataSource={items}
          height='auto'
          itemRender={ItemTemplate} />
        <br /> 
        <hr />
        {newButton}
      </React.Fragment>
    );
  } else {
    return <React.Fragment>
      Nessun tesseramento collegato. <br />
      {newButton}
    </React.Fragment>
  }
}
