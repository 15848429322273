import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Button, Column, Lookup, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage, useAuth, useSvcCustomRequest, useSvcLookupDataGridColumnStore } from '@/svc';
import UtentiChangePasswordForm from './utenti-change-password-form';
import { Toast } from '@/ui/utils';
import { Modal } from '@/ui/components';

export default function UtentiPage() {
  const { user } = useAuth();
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [utentiChangePasswordRecord, setUtentiChangePasswordRecord] = useState(null);
  const utentiChangePasswordFormRef = useRef();
  const isUserAdmin = useMemo(() => user.IsAdmin === true, [user]);
  

  const { customRequest } = useSvcCustomRequest('Auth', 'utenti');
  const changePasswordCall = useCallback((datarow) => {
    return customRequest({
      command: 'ChangePassword',
      utentiRecord: datarow
    });
  }, [ customRequest ]);

  const displayLivello = process.env.REACT_APP_USE_UTENTI_LIVELLO === '1';

  /**
   * Chiude la modale di cambio password senza compiere nessuna azione.
   */
  const onUndoClick = () => {
    setChangePasswordModalVisible(false);
  };

  /**
   * Valida il form e tenta il cambio password.
   */
  const onConfirmChangePasswordModalClick = async () => {
    const { isValid } = utentiChangePasswordFormRef.current.validateForm();
    if (isValid) {
      try {
        await changePasswordCall(utentiChangePasswordRecord);
        Toast('success', 'Password cambiata con successo!');
        setChangePasswordModalVisible(false);
      }
      catch (e) {
        Toast('error', e.message, 8000);
      }
    }
    else {
      Toast('error', 'Completa tutti i campi obbligatori.', 4000);
    }
  };



  // Apre la modale per il cambiamento della password.
  const userChangePassword = (evt) => {
    setUtentiChangePasswordRecord(evt.row.data);
    setChangePasswordModalVisible(true);
  }

  const livelliDatasource = useSvcLookupDataGridColumnStore({
    domain: 'Auth',
    entity: 'livelli'
  });

  return <EntityListPage
    domain='Auth'
    entity='utenti'
    editRoute='/auth/Utenti/edit'
  >
    <EntityListPage.RowButtons>
      <Button
        visible={isUserAdmin}
        icon='fa fa-key'
        hint='Modifica Password'
        onClick={userChangePassword} />
    </EntityListPage.RowButtons>
    <EntityListPage.Columns>
    <Modal visible={changePasswordModalVisible}>
      <Modal.Title>
        Modifica password utente
      </Modal.Title>
      <UtentiChangePasswordForm ref={utentiChangePasswordFormRef} datarow={utentiChangePasswordRecord} />
      <Modal.Button type="danger" onClick={onUndoClick}>Annulla</Modal.Button>
      <Modal.Button type="success" onClick={onConfirmChangePasswordModalClick}>Conferma</Modal.Button>
    </Modal>
      <Column
        caption='Nome'
        dataField='nome'
        dataType='string'
        >
        <RequiredRule />
      </Column>
      <Column
        caption='Username'
        dataField='username'
        dataType='string'>
        <RequiredRule />
      </Column>
      <Column
        visible={displayLivello}
        caption='Livello'
        dataField='livelloRecord.id'
        dataType='string'>
          <Lookup
            dataSource={livelliDatasource}
            displayExpr="descrizione"
            valueExpr="id"
          />
          <RequiredRule />
      </Column>
    </EntityListPage.Columns>
  </EntityListPage>
}