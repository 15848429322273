import React, { createContext, useEffect, useState } from 'react';

import { SvcClient } from '@/svc';

export const SvcContext = createContext({});

export function SvcProvider(props) {
  const [ aziendaAs, setAziendaAs ] = useState(props.aziendaAs);
  const [ client, setClient ] = useState(props.client);
  const [ user, setUser ] = useState(props.user);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    if (!user) {
      return;
    }

    const stringifiedUser = JSON.stringify(user);
    window.sessionStorage.setItem('user', stringifiedUser);
  }, [ user ]);

  useEffect(() => {
    if (!loading) {
      return;
    }

    const stringifiedUser = window.sessionStorage.getItem('user');
    if (stringifiedUser) {
      setUser(JSON.parse(stringifiedUser));
    }
    
    setLoading(false);
  }, [ client, loading, setUser, setLoading ]);

  useEffect(() => {
    if (!client) {
      return;
    }

    const newClient = new SvcClient({ ...client.config, aziendaAs });
    setClient(newClient);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ aziendaAs, setClient ]);

  return (
    <SvcContext.Provider
      value={{ aziendaAs, setAziendaAs, client, setClient, user, setUser }}
      children={props.children} />
  );
}
