import React from 'react';

import { SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';

import { FlagsListComponent } from '@/ui/components';
import { EntityEditPage, useAuth, useSvcSelectBoxConfig } from '@/svc';

export default function PeriodiImpostazioniEditPage() {
  const { user } = useAuth();

  const aziendeSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Auth',
    entity: 'aziende',
    displayExpr: 'ragionesociale',
    paginate: true
  });

  return <EntityEditPage
    domain='Book'
    entity='periodiImpostazioni'
  >
    <EntityEditPage.EditForm>
      {(datarow, setDatarowField) => (
        <React.Fragment>
          {user.IsAdmin && (
            <SimpleItem
              dataField="azienda"
              editorType="dxSelectBox"
              editorOptions={aziendeSelectBoxConfig}>
                <Label text="Azienda" />
                <RequiredRule />
            </SimpleItem>
          )}
          <SimpleItem colSpan={1} dataField='data_da' editorType='dxDateBox'>
            <Label text="Data (dal)" />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem colSpan={1} dataField='data_a' editorType='dxDateBox'>
          <Label text="Data (al)" />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem colSpan={1} dataField='soggiorno_minimo' editorType='dxNumberBox'>
            <RequiredRule />
          </SimpleItem>
          <EmptyItem colSpan={1} />
          <SimpleItem colSpan={4}>
            <Label text="Lista Proprietà" />
            <FlagsListComponent
              items={datarow.flagsList}
              setItems={setDatarowField('flagsList')}
              direction='row'
            />
          </SimpleItem>   
        </React.Fragment>
      )}
    </EntityEditPage.EditForm>
  </EntityEditPage>
}