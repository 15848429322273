import React, { useCallback, useRef, useState } from 'react';

import Modal from './modal';

export default function useModalAlert(opts = {}) {
  const [ body, setBody ] = useState(opts.body);

  const [ visible, setVisible ] = useState(false);
  const resolveRef = useRef();

  const openModal = useCallback((body) => {
    setVisible(true);

    if (body) {
      setBody(body);
    }

    return new Promise(resolve => resolveRef.current = resolve);
  }, [ setVisible, setBody ]);

  const closeModal = useCallback((value) => {
    setVisible(false);

    resolveRef.current(value)
  }, [ setVisible ]);

  const modal = (
    <Modal
      title={opts.title}
      visible={visible}
      onClose={closeModal}
    >
      {body}
      <Modal.Button onClick={() => closeModal(true)}>Ok</Modal.Button>
    </Modal>
  );

  return [ modal, openModal, setBody ];
}