import React, { useMemo } from 'react';

import { Column, RequiredRule, Lookup } from 'devextreme-react/data-grid';

import { EntityListPage, useAuth, useSvcLookupDataGridColumnStore } from '@/svc';

export default function TipoCameraDisponibilitaPage() {
  const tipoCameraAziendaColumnDatasource = useSvcLookupDataGridColumnStore({
    domain: 'Book',
    entity: 'tipoCameraAzienda'
  });
  
  return <EntityListPage
    domain='Book'
    entity='tipoCameraDisponibilita'
    editRoute='/book/TipoCameraDisponibilita/edit'
    newButtonOptions={{
      icon: null,
      text: 'imposta disponibilità'
    }}
  >
    <EntityListPage.Columns>
      <Column
        caption='Data'
        dataField='data'
        dataType='date'>
          <RequiredRule />
      </Column>
      <Column
        caption='Camere Libere'
        dataField='num_camere'
        dataType='number'>
          <RequiredRule />
      </Column>
      <Column
        caption='Tipo Camera'
        dataField='tipoCameraAzienda.id'
        dataType='string'
        >
          <Lookup
            dataSource={tipoCameraAziendaColumnDatasource}
            displayExpr="displayText"
            valueExpr="id"
          />
          <RequiredRule />
      </Column>
      <Column
        caption='Ultimo aggiornamento'
        dataField='last_update'
        dataType='datetime'>
          <RequiredRule />
      </Column>
    </EntityListPage.Columns>
  </EntityListPage>
}