import React, { useState } from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { TreeSelectBox } from '@/ui/components';
import { EntityEditPage, useSvcDatasource, useSvcCustomRequest, useSvcSelectBoxConfig } from '@/svc';

export default function NomineOrganigrammaEditPage() {
  //const [clienteDisabled, setClienteDisabled] = useState(null);
  const [clientiSelectLoadDatasource, setClientiSelectLoadDatasource] = useState(null);
  const { customRequest } = useSvcCustomRequest('Core', 'clienti');

  const clientiSelectBox = useSvcSelectBoxConfig({
    domain: 'Core',
    entity: 'clienti',
    //disabled: clienteDisabled,
    paginate: true,
    datasourceProps: clientiSelectLoadDatasource
  });

  const onDatarowReady = (datarow) => {
    //if (clienteDisabled === null) toggleAndFilterClienti(datarow.contatto);
  };
  const onContattoChanged = ({ value }) => {
    //toggleAndFilterClienti(value);
  };
  /*
  const toggleAndFilterClienti = async (contatto) => {
    if (contatto) {
      setClienteDisabled(false);
      setClientiSelectLoadDatasource({
        load: async () => {
          const ds = await customRequest({
            command: 'ListByContatto',
            key: contatto.id
          });
          return ds.clientiList || [];
        }
      });
    }
    else {
      setClienteDisabled(true);
      setClientiSelectLoadDatasource(null);
    }
  };*/
  
  const contattiSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Core',
    entity: 'contatti',
    paginate: true,
    onValueChanged: onContattoChanged,
    filters: {
      contattiFlagsList:[
        {
          codice: 'EXT',
          selezionato: true
        }
      ]
    }
  });
  /*
  const organigrammaSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'HR',
    entity: 'organigramma'
  });
  */
  const organigrammaDataSource = useSvcDatasource({
    domain: 'HR',
    entity: 'organigramma'
  });

  const clienteLabel = process.env.REACT_APP_ENTITY_CLIENTE_ALIAS ?? 'Cliente';

  return (
    <EntityEditPage
      domain='HR'
      entity='nomineOrganigramma'
      onDatarowReady={onDatarowReady}>
        <EntityEditPage.EditForm>
          {(datarow, setDatarowField) => (
            <React.Fragment>
              <SimpleItem
                dataField='clientiRecord'
                editorType='dxSelectBox'
                colSpan={2}
                editorOptions={clientiSelectBox}>
                  <Label text={clienteLabel} />
                  <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='contatto'
                editorType='dxSelectBox'
                colSpan={2}
                editorOptions={contattiSelectBoxConfig}>
                  <Label text='Contatto' />
                  <RequiredRule />
              </SimpleItem>
              {/*
              <SimpleItem
                dataField='ruolo'
                editorType='dxSelectBox'
                colSpan={2}
                editorOptions={organigrammaSelectBoxConfig}>
                  <Label text='Nomina' />
                  <RequiredRule />
              </SimpleItem>
              */}
              <SimpleItem>
                  <Label text="Nomina" />
                  <TreeSelectBox
                    dataSource={organigrammaDataSource}
                    value={datarow.ruolo}
                    onValueChanged={setDatarowField('ruolo')} />
              </SimpleItem>
              <SimpleItem
                dataField='data_nomina'
                editorType="dxDateBox"
              >
                <Label text="Data Nomina" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='scadenza_nomina'
                editorType="dxDateBox"
              >
                <Label text="Scadenza Nomina" />
                <RequiredRule />
              </SimpleItem>
            </React.Fragment>
          )}
        </EntityEditPage.EditForm>
    </EntityEditPage>
  );
}