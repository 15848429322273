export default class SvcError extends Error {
  constructor(endpoint, status, message) {
    super(message);
    this.endpoint = endpoint;
    this.status = status;
    this.name = 'SvcError';
  }

  toString() {
    return `[${this.status}] ${this.endpoint}: ${this.message}`;
  }
}