import React from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage } from '@/svc';

export default function CorsiTipologiaEditPage() {
  return (
    <EntityEditPage
      domain='Xtra'
      entity='corsiTipologia'>
        <EntityEditPage.EditForm>
          {(datarow) => (
            <React.Fragment>
              <SimpleItem
                colSpan={2}
                dataField="nome"
                editorType="dxTextBox"
              >
                <Label text="Nome" />
                <RequiredRule />
              </SimpleItem>
            </React.Fragment>
          )}
        </EntityEditPage.EditForm>
    </EntityEditPage>
  );
}