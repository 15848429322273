import './user-panel.scss';

import React, { useCallback, useMemo, useRef, useState } from 'react';

import Form, { Label, RequiredRule, SimpleItem } from 'devextreme-react/form';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';

import { useAuth } from '@/svc';
import { Modal } from '@/ui/components';
import { Toast } from '@/ui/utils';

const UserPanelChangePasswordModal = (props) => {
  const { changePassword } = useAuth();
  const { modalVisible, setModalVisible } = props;

  const changePasswordFormRef = useRef();

  const [formData, _setFormData] = useState({});
  const changePasswordTextBoxConfig = {
    mode: 'password'
  };

  const promptChangePassword = useCallback(async () => {
    try {
      await changePassword(
        formData.oldPassword,
        formData.newPassword, 
        formData.confirmNewPassword
      );
      Toast('success', 'Password modificata con successo!', 3000);
      setModalVisible(false);
      _setFormData({});
    }
    catch (e) {
      Toast('error', e.message, 5000);
    }
  }, [formData]);

  const onUndoClick = useCallback(() => {
    setModalVisible(false);
    _setFormData({});
  }, []);
  
  const onConfirmChangePasswordClick = useCallback(() => {
    if (validateForm()) {
      promptChangePassword();
    }
  }, [changePasswordFormRef]);

  const validateForm = useCallback(() => {
    const { isValid } = changePasswordFormRef.current.instance.validate();
    return isValid;
  }, [changePasswordFormRef]);

  return <Modal visible={modalVisible} minWidth={'50vw'}>
    <Modal.Title>
      Cambia Password
    </Modal.Title>
    <Form
      ref={changePasswordFormRef}
      colCount={1}
      formData={formData}
    >
      <SimpleItem 
        dataField="oldPassword" 
        editorType="dxTextBox"
        editorOptions={changePasswordTextBoxConfig}>
        <Label text="Password Attuale" />
        <RequiredRule />
      </SimpleItem>
      <SimpleItem 
        dataField="newPassword" 
        editorType="dxTextBox"
        editorOptions={changePasswordTextBoxConfig}>
        <Label text="Nuova Password" />
        <RequiredRule />
      </SimpleItem>
      <SimpleItem 
        dataField="confirmNewPassword" 
        editorType="dxTextBox"
        editorOptions={changePasswordTextBoxConfig}>
        <Label text="Conferma Nuova Password" />
        <RequiredRule />
      </SimpleItem>
    </Form>
    <Modal.Button type="danger" onClick={onUndoClick}>Annulla</Modal.Button>
    <Modal.Button type="success" onClick={onConfirmChangePasswordClick}>Conferma</Modal.Button>
  </Modal>;
}

export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();

  const [displayChangePasswordModal, setDisplayChangePasswordModal] = useState(false);
  
  const promptChangePassword = () => {
    setDisplayChangePasswordModal(true);
  };

  const menuItems = useMemo(() => {
    const items = [
      {
        text: 'Cambia Password',
        icon: 'fa fa-key',
        onClick: promptChangePassword
      },
      {
        text: 'Esci',
        icon: 'runner',
        onClick: signOut
      }
    ];

    if (user.manuale) {
      items.push({
        text: 'Manuale',
        icon: 'fa fa-book',
        onClick: () => window.open(user.manuale, '_blank')
      });
    }

    return items;
  }, [ signOut ]);

  return (
    <>
      <div className='user-panel'>
        <div className='user-info'>
          {/*
          <div className='image-container'>
            <div
              style={{
                background: `url(${user.avatarUrl}) no-repeat #fff`,
                backgroundSize: 'cover'
              }}
              className='user-image' />
          </div>
          */}
          <div className='user-name'>{user.nome}</div>
        </div>

        {menuMode === 'context' && (
          <ContextMenu
            items={menuItems}
            target='.user-button'
            showEvent='dxclick'
            width={210}
            cssClass='user-menu'
          >
            <Position my='top center' at='bottom center' />
          </ContextMenu>
        )}
        {menuMode === 'list' && (
          <List className='dx-toolbar-menu-action' items={menuItems} />
        )}
      </div>
      <UserPanelChangePasswordModal
        modalVisible={displayChangePasswordModal}
        setModalVisible={setDisplayChangePasswordModal}
      />
    </>
  );
}
