import React from 'react';

import NumberBox from 'devextreme-react/number-box';

export default function MaxEntriesFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  return (
    <NumberBox
      min={0}
      value={value}
      onValueChanged={({ value }) => {
        if (!value) {
          setValue(null);
          return;
        }

        const num = parseInt(value);
        setValue(num);
      }}
      showClearButton={true}
    />
  );
}

MaxEntriesFilter.key = 'maxEntries';
MaxEntriesFilter.label = 'Numero massimo di voci';