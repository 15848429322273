import React from 'react';

import { TextBox } from 'devextreme-react';

export default function PIVAFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  return (
    <TextBox
      value={value}
      onValueChanged={({ value }) => {
        setValue(value);
      }}
      showClearButton={true}
    />
  );
}

PIVAFilter.key = 'piva';
PIVAFilter.label = 'Partita IVA';
