import { useCallback, useContext, useEffect, useState } from 'react';

import { SvcContext } from '@/svc';

export default function useSvcList(domain, entity, filters = {}, { manual = false } = {}) {
  const { client, user } = useContext(SvcContext);

  const [ loading, setLoading ] = useState(!manual);
  const [ error, setError ] = useState(null);
  const [ data, setData ] = useState(null);
  const [ hasCloneCommand, setHasCloneCommand ] = useState(false);

  const token = user.token_login;
  const stringifiedFilters = JSON.stringify(filters);

  const fetchData = useCallback(() => {
    setLoading(true);
    setError(null);
    setData(null);

    return client.listEntity(domain, entity, filters, { token })
      .then(([data, list]) => {
        setData(list);
        const { hasCloneCommand } = data;
        setHasCloneCommand(!!hasCloneCommand);
      }, setError)
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ domain, entity, stringifiedFilters, token, setLoading, setError, setData ]);

  useEffect(() => {
    if (!manual) fetchData();
  }, [ manual, fetchData ]);

  return [ { loading, error, data, hasCloneCommand }, fetchData ];
}