import React from 'react';

import { Column } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function CorsiPage() {
  return (
    <EntityListPage
      domain='Xtra'
      entity='corsi'
      editRoute='/xtra/Corsi/edit'>
        <EntityListPage.Filters
          maxEntries={{
            initialValue: 100
          }}
          corsiTipologiaRecord={{
            initialValue: null
          }}
          genericText={{
            initialValue: null,
            label: 'Nome'
          }}
        />
        <EntityListPage.Columns>
          <Column
            caption='Nome'
            dataField='displayText'
            dataType='string'
             />
          <Column
            caption='Durata Ore'
            dataField='durata_ore'
            dataType='number' />
          <Column
            caption='Periodicità Mesi'
            dataField='periodicita_mesi'
            dataType='number' />
          <Column
            caption='Tipologia'
            dataField='tipologia.displayText'
            dataType='string' />
          <Column
            caption='Titolare Corso'
            dataField='titolare.displayText'
            dataType='string' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}