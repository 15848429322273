import React, { Fragment, useContext, useEffect } from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';

import { NavContext } from '@/navigation';
import { appName } from '@/ui/config';
import { ErrorPage } from '@/ui/pages';
import routes from '@/ui/pages/routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './templates';

export default function AuthedLayout() {
  const { homeRoute } = useContext(NavContext);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => history.push(homeRoute), []);

  return (
    <SideNavBarLayout title={appName}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route exact key={path} path={path} component={component} />
        ))}
        <Route component={NotFoundPanel} />
      </Switch>
    </SideNavBarLayout>
  );
}

function NotFoundPanel() {
  const msg = 'La pagina che stai cercando non è stata trovata, '
    + 'ha cambiato nome oppure è temporaneamente non disponibile.';

  return (
    <Fragment>
      <h2 className='content-header'>Pagina non trovata</h2>
      <ErrorPage message={msg} />
    </Fragment>
  );
}