import React from 'react';

import { Column } from 'devextreme-react/data-grid';

import { EntityListPage, useAuth } from '@/svc';

export default function PeriodiImpostazioni() {
  const { user } = useAuth();

  return (
    <EntityListPage
      domain='Book'
      entity='periodiImpostazioni'
      editRoute='/book/PeriodiImpostazioni/edit'>
        <EntityListPage.Filters
          maxEntries={{
            initialValue: 100
          }}
          data_inizio={{
            initialValue: void 0
          }}
        />
        <EntityListPage.Columns>
          {user.IsAdmin && (
            <Column
              caption={'Azienda'}
              dataField='azienda.ragionesociale'
              dataType='string' />  
          )}
          <Column
            caption='Arrivo'
            dataField='data_da'
            dataType='datetime' />
          <Column
            caption='Partenza'
            dataField='data_a'
            dataType='datetime' />
          <Column
            caption='Soggiorno minimo'
            dataField='soggiorno_minimo'
            dataType='number' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}