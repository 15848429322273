import React from 'react';

import { Column, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function AziendePage() {
  return (
    <EntityListPage
      domain='Auth'
      entity='aziende'
      editRoute='/auth/Aziende/edit'
    >
      <EntityListPage.Columns>
        <Column
          caption='Ragione Sociale'
          dataField='ragionesociale'
          dataType='string'>
            <RequiredRule />
        </Column>
      </EntityListPage.Columns>
    </EntityListPage>
  );
}
