import React from 'react';

import { useSvcDatasource } from '@/svc';
import { TreeSelectBox } from '@/ui/components';

export default function ClientiCategorieList(props) {
  const { value, setValue } = props.data.editorOptions;

  const clientiCategorieDataSource = useSvcDatasource({
    domain: 'Core',
    entity: 'clientiCategorie'
  });

  return (
    <TreeSelectBox
      multiple
      dataSource={clientiCategorieDataSource}
      value={value}
      onValueChanged={setValue}
    />
  );
}

ClientiCategorieList.key = 'clientiCategorieList';
ClientiCategorieList.label = 'Categorie clienti';
