import { useCallback, useContext, useEffect, useState } from 'react';

import { SvcContext } from '@/svc';

export default function useSvcFilters(
  domain, entity, initialValue = {}, filtersResponseKey = 'filtriNew'
) {
  const { client, user } = useContext(SvcContext);

  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ filters, setFilters ] = useState(initialValue);

  const updateFilters = useCallback((newFilters) => {
    setFilters({ ...filters, ...newFilters });
  }, [ filters, setFilters ]);

  const token = user.token_login;
  useEffect(() => {
    setLoading(true);
    setError(null);
    setFilters(initialValue);

    client.filtersEntity(domain, entity, { token, responseKey: filtersResponseKey })
      .then(updateFilters, setError)
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ domain, entity, token, setLoading, setError, setFilters ]);

  return { loading, error, filters, updateFilters };
}