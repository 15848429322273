import React, { useCallback, useState } from 'react';

import { SmsTextBox } from '@/ui/components';
import { EntitySendSmsDialog } from '..';

export default function SvcSmsTextBox(props) {
  const {
    domain, entity, defaultData = {},
    value, setValue,
    editorOptions
  } = props;

  const [displaySmsDialog, setDisplaySmsDialog] = useState(false);

  const onSendSmsButtonClick = (evt) => {
    setDisplaySmsDialog(true);
  };

  const onModalClosed = useCallback((results) => {
    setDisplaySmsDialog(false);
  }, []);

  return <>
    <SmsTextBox
      value={value}
      valueChanged={(newValue) => setValue(newValue)}
      editorOptions={{
        placeholder: 'Numero di cellulare',
        ...editorOptions
      }}
      onSendSmsButtonClick={onSendSmsButtonClick}
    />
    <EntitySendSmsDialog
      domain={domain}
      entity={entity}
      defaultData={{
        ...defaultData,
        smsNumber: value
      }}
      modalTitle='Invia SMS'
      modalVisible={displaySmsDialog}
      onModalClosed={onModalClosed}
    />
  </>
}
