import React, { useContext } from 'react';

import { Column, Button } from 'devextreme-react/tree-list';

import { EntityTreeList } from '@/svc'
import { NavContext } from '@/navigation';

export default function TreeList(props) {
  const {
    domain, entity, filters,
    columns, rowButtons,
    onInit, onEdit, onClone,
  } = props;

  const { currentRoute: { readOnly } } = useContext(NavContext);

  return (
    <EntityTreeList
      domain={domain}
      entity={entity}
      filters={filters}
      onInitialized={(e) => onInit && onInit(e)}
    >
      {columns}
      <Column type='buttons'>
        {rowButtons}
        {/* <Button visible={} icon='repeat' onClick={onClone} /> */}
        <Button icon={readOnly ? 'info' : 'edit'} onClick={onEdit} />
      </Column>
    </EntityTreeList>
  );
}